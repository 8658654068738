import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities, onGetCartItems } from '../../util/data';
import { storableError } from '../../util/errors';
import { CART_TYPE } from '../../util/types';

const { UUID } = sdkTypes;


// ================ Action types ================ //

export const NON_LOGGED_USER = 'app/CartPage/NON_LOGGED_USER';

export const UPDATE_USER_REQUEST = 'app/CartPage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/CartPage/UPDATE_USER_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/CartPage/UPDATE_USER_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/CartPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/CartPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/CartPage/QUERY_LISTINGS_ERROR';

export const SAVE_LATER_LISTINGS_REQUEST = 'app/CartPage/SAVE_LATER_LISTINGS_REQUEST';
export const SAVE_LATER_LISTINGS_SUCCESS = 'app/CartPage/SAVE_LATER_LISTINGS_SUCCESS';
export const SAVE_LATER_LISTINGS_ERROR = 'app/CartPage/SAVE_LATER_LISTINGS_ERROR';

export const AUTHOR_REVIEWS_SUCCESS = 'app/CartPage/AUTHOR_REVIEWS_SUCCESS';


// ================ Reducer ================ //

const initialState = {
  queryListingsRequest: false,
  queryListingsError: null,
  userListingRefs: [],
  updateCartError: null,
  updateCartInProgress: false,
  saveLaterListingsRequest: false,
  saveLaterListingRefs: [],
  saveLaterListingsError: null,
  authorReviews: [],
  nonLoggedUser: {},
};

export default function CartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsError: null,
        queryListingsRequest: true
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, queryListingsRequest: false };

    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload, queryListingsRequest: false };

    case NON_LOGGED_USER:
      const nonLoggedUserData = typeof window != 'undefined' && window.sessionStorage.getItem('nonLoggedUser');
      const nonLoggedUser = nonLoggedUserData ? JSON.parse(nonLoggedUserData) : {};
      if (nonLoggedUser && nonLoggedUser.publicData) {
        Object.assign(nonLoggedUser.publicData, {
          ...payload.publicData
        });
      } else {
        Object.assign(nonLoggedUser, { ...payload });
      }

      typeof window != 'undefined' && window.sessionStorage.setItem('nonLoggedUser', JSON.stringify(nonLoggedUser));

      return { ...state, nonLoggedUser };

    case SAVE_LATER_LISTINGS_REQUEST:
      return {
        ...state,
        saveLaterListingsError: null,
        saveLaterListingsRequest: true
      };
    case SAVE_LATER_LISTINGS_SUCCESS:
      return { ...state, saveLaterListingRefs: payload.saveLaterListingRefs, saveLaterListingsRequest: false, saveLaterListingsError: null };

    case SAVE_LATER_LISTINGS_ERROR:
      return { ...state, saveLaterListingRefs: [], saveLaterListingsError: payload, saveLaterListingsRequest: false };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updateCartError: null,
        updateCartInProgress: payload
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateCartError: null,
        updateCartInProgress: false
      };

    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateCartError: true,
        updateCartInProgress: false
      };

    case AUTHOR_REVIEWS_SUCCESS:
      return {
        ...state,
        authorReviews: payload,
      }

    default:
      return state;
  }
}

// ================ Action creators ================ //


export const queryListingsRequest = data => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: data,
});

export const queryListingsSuccess = data => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: data,
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const nonLoggedUser = data => ({
  type: NON_LOGGED_USER,
  payload: data,
});

export const saveLaterListingsRequest = data => ({
  type: SAVE_LATER_LISTINGS_REQUEST,
  payload: data,
});

export const saveLaterListingsSuccess = data => ({
  type: SAVE_LATER_LISTINGS_SUCCESS,
  payload: data,
});

export const saveLaterListingsError = e => ({
  type: SAVE_LATER_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const updateCartInProgress = (type) => ({
  type: UPDATE_USER_REQUEST,
  payload: type
});

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
});

export const updateCartError = () => ({
  type: UPDATE_USER_ERROR,
});

export const authorsReviewSuccess = (data) => ({
  type: AUTHOR_REVIEWS_SUCCESS,
  payload: data
});


// ================ Thunks ================ //
// queryListingsRequest , queryListingsSuccess , queryListingsError
export const queryUserListings = (ids, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(true));

  if (ids) {
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;
    // sdk.ownListings.query sdk.listings.query
    return sdk.listings.query({
      ids,
      include: ['author', 'author.profileImage', 'images', 'currentStock'],
      'fields.image': [
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.default`,
        'variants.scaled-small',
        'variants.scaled-medium',
        "variants.square-small",
        "variants.square-small2x",
        "variants.xsquare-small",
        "variants.xsquare-small2x"],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
      .then(response => {
        // Pick only the id and type properties from the response listings
        const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
        dispatch(addMarketplaceEntities(response));
        dispatch(queryListingsSuccess({ listingRefs }));

        // update cart Values if any listing is deleted 
        try {
          const { currentUser } = getState().user;
          const { cartListings = [] } = onGetCartItems(currentUser);
          // check the condition if listingRefs length is less than cartListings it means any listing is closed or deleted
          if (listingRefs.length < cartListings.length) {
            console.log('&&& we are updating the cart &&& => ');
            const newCartListings = cartListings.filter((st) => listingRefs.findIndex((lr) => lr?.id?.uuid == st.listingId) >= 0);
            const params = {
              publicData: {
                cartListings: newCartListings,
                cartLength: newCartListings.length
              }
            };
            dispatch(updateCartProfile(params, CART_TYPE));
          }
        } catch (e) {
          console.log(e, '&&& queryUserListings >> updateCartProfile &&& => e');
        }

        return response;
      })
      .catch(e => dispatch(queryListingsError(storableError(e))));
  }

  return dispatch(queryListingsSuccess({ listingRefs: [] }));

};

// saveLaterListingsRequest saveLaterListingsSuccess saveLaterListingsError
export const querySaveLaterUserListings = (ids, config) => (dispatch, getState, sdk) => {
  if (ids) {
    dispatch(saveLaterListingsRequest(true));
    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;
    const aspectRatio = aspectHeight / aspectWidth;
    // sdk.ownListings.query sdk.listings.query
    return sdk.listings.query({
      ids: ids,
      include: ['author', 'author.profileImage', 'images', 'currentStock'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`, `variants.default`, 'variants.scaled-small',
        'variants.scaled-medium', "variants.square-small", "variants.square-small2x", "variants.xsquare-small", "variants.xsquare-small2x"],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
      .then(response => {
        // Pick only the id and type properties from the response listings
        const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
        dispatch(addMarketplaceEntities(response));
        dispatch(saveLaterListingsSuccess({ saveLaterListingRefs: listingRefs }));

        // update cart Values if any listing is deleted 
        try {
          const currentUser = getState().user.currentUser;
          const { saveLaterListings = [] } = onGetCartItems(currentUser);
          // check the condition if listingRefs length is less than saveLaterListings it means any listing is closed or deleted
          if (listingRefs.length < saveLaterListings.length) {
            console.log('&&& we are updating the cart &&& => ');
            const newCartListings = saveLaterListings.filter((st) => listingRefs.findIndex((lr) => lr?.id?.uuid == st.listingId) >= 0);
            const params = {
              publicData: {
                saveLaterListings: newCartListings
              }
            };
            dispatch(updateCartProfile(params, CART_TYPE))
          }
        } catch (e) {
          console.log(e, '&&& queryUserListings >> updateCartProfile &&& => e');
        }

        return response;
      })
      .catch(e => dispatch(saveLaterListingsError(storableError(e))));
  } else {
    dispatch(saveLaterListingsRequest(true));
    dispatch(saveLaterListingsSuccess({ saveLaterListingRefs: [] }));
  }
};


export const allReviewsAuthor = (ids) => (dispatch, getState, sdk) => {
  try {
    if (ids && ids.length) {
      const uniqueAuthorIds = [...new Set(ids)];
      const allReviews = uniqueAuthorIds.map((st) => sdk.reviews.query({ subject_id: new UUID(st), state: 'public', }));
      return Promise.all(allReviews)
        .then(res => {
          // console.log(res, '&&& &&& => res');

          const userReviews = uniqueAuthorIds.map((item, index) => {
            const reviewArray = (Array.isArray(res[index]?.data?.data) && res[index]?.data?.data) || []
            const rating = reviewArray.reduce((acc, curr) => acc + (curr?.attributes?.rating || 0), 0);

            return {
              authorId: item,
              rating: rating && reviewArray.length ? Math.round((rating) / reviewArray.length) : 0,
              totalRating: reviewArray.length
            }
          });

          dispatch(authorsReviewSuccess(userReviews));
          return res;
        })
        .catch(e => {
          console.log(e, '&&& &&& => e');
        })
    }
  } catch (e) {
    console.log(e, '&&& &&& => e');
  }
};


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return dispatch(fetchCurrentUser())
    .then((currentUser) => {
      const { cartListings, saveLaterListings } = onGetCartItems(currentUser);

      const cartListingIds = Array.isArray(cartListings)
        ? cartListings.map((st) => st.listingId).join(",")
        : false;
      const allCartAuthors = Array.isArray(cartListings)
        ? cartListings.map((st) => st.authorId)
        : false;
      const saveLaterListingIds = Array.isArray(saveLaterListings)
        ? saveLaterListings.map((st) => st.listingId).join(",")
        : false;

      console.log('**** loadData ****');

      return Promise.all([
        dispatch(queryUserListings(cartListingIds, config)),
        dispatch(querySaveLaterUserListings(saveLaterListingIds, config)),
        dispatch(allReviewsAuthor(allCartAuthors))
      ]);
    });
};


export const updateCartProfile = (actionPayload, type) => {
  return (dispatch, getState, sdk) => {
    const { isAuthenticated } = getState().auth;

    if (!isAuthenticated) {
      return new Promise((resolve) => {
        dispatch(nonLoggedUser(actionPayload));
        resolve();
      });
    }

    dispatch(updateCartInProgress(type));

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateUserSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js

        dispatch(currentUserShowSuccess(currentUser));
        return currentUser;
      })
      .catch(e => dispatch(updateCartError(storableError(e))));
  };
};