import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import { SINGLE_PRODUCT_COLLECTION, collectionValuesCheckVariants, expectionCollectionValuesCheck, freeShipping, subCategoryChild } from '../config/configListing';
import { ALOGOLIA_SEARCH_ATTRIBUTES, ADD_MANAGE_LISTING_REMOVE, ADD_MANAGE_LISTING_TYPE, ADD_MANAGE_LISTING_UPDATE, CART_ITEMS_MAIL_STATUS_PENDING, LISTING_CARD_2X, REVIEW_TYPE_OF_PROVIDER, SHARETRIBE_TYPE_IMAGE, allVariantTypes, algoliaSortOptions, SEARCH_KEY_PARAMS, ALGOLIA_SEARCH_KEY_PARAMS } from './types';
import moment from 'moment';
// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse, sanitizeConfig = {}) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' && value?._ref?.type === 'imageAsset' && value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef = typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasDisplayName = user?.attributes?.profile?.publicData?.storeName || user?.attributes?.profile?.displayName;
  if (hasDisplayName) {
    return hasDisplayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};



export const sortAccorrdingToHeroImage = (listing) => {
  const listingImages = listing?.images || [];
  const { heroImageId = false } = listing?.attributes?.publicData || {};
  const sortImages = heroImageId && Array.isArray(listingImages) && listingImages.length ? listingImages.map((st) => ({ ...st, sortId: st.id.uuid == heroImageId ? 1 : 0 })).sort((a, b) => b.sortId - a.sortId) : listingImages;
  return sortImages;
}


const particularVariantValues = (variants, collectionType, data) => {
  return variants.filter((st) => {
    const getCollectionCheckIndex = collectionValuesCheckVariants.findIndex((st) => st.key == collectionType);
    const checkValuesArray = getCollectionCheckIndex >= 0 ? collectionValuesCheckVariants[getCollectionCheckIndex].checkValuesArray : [];
    // checkValuesArray present 
    if (checkValuesArray.length) {
      return checkValuesArray.every(ev => {
        const checkExceptionValueIndex = expectionCollectionValuesCheck.findIndex((ex) => ex.key == ev);
        if (checkExceptionValueIndex >= 0) {
          const exceptionKey = expectionCollectionValuesCheck[checkExceptionValueIndex].value;
          return (st[exceptionKey] && data[exceptionKey] && st[exceptionKey] == data[exceptionKey]) || (st[ev] && data[ev] && st[ev] == data[ev]);
        }
        else {
          return st[ev] && data[ev] && st[ev] == data[ev];
        }
      })
    }
    // if no checkValuesArray present 
    else {
      return false;
    }

  })
}



const getItemAccToCollectionType = (listing, data) => {
  const { price, publicData } = listing.attributes;
  const { additionalDeliveryFee, collectionType, variants, stockDefault = 0, totalStockAvailable, overSizedDeliveryFee } = publicData;

  if (collectionType == SINGLE_PRODUCT_COLLECTION) {
    return {
      variantPrice: price.amount,
      overSizedDeliveryFee: overSizedDeliveryFee ? (+overSizedDeliveryFee) * 100 : 0,
      additionalDeliveryFee,
      currentStock: (+stockDefault),
      totalStockAvailable,
    };
  }

  else {
    let minPrice = 9999999999999999;
    variants && variants.length && variants.forEach((itm) => (+itm.price) < minPrice ? minPrice = (+itm.price) : null);
    // filter the values based on collection type and data 
    const filterVariant = collectionType && variants && variants.length ? particularVariantValues(variants, collectionType, data) : [];

    // each variant data you can get from this 
    const { price: variantPrice = 0, overSizedDeliveryFee = 0, quantity = 0 } = filterVariant.length && typeof filterVariant[0] == "object" ? filterVariant[0] : {};

    return {
      variantPrice: variantPrice ? getExactPrice((+variantPrice) * 100) : getExactPrice((+minPrice) * 100),
      currentStock: (+quantity),
      overSizedDeliveryFee,
      additionalDeliveryFee,
      totalStockAvailable,
    };
  }
};


// cart functions 
export const getCartListingData = (listing, data) => {
  const { title, price } = listing?.attributes || {};
  const { productTitle } = listing?.attributes?.publicData || {};
  const images = sortAccorrdingToHeroImage(listing);
  const { afterPayFee = 0 } = listing?.author?.attributes?.profile?.publicData || {};
  const { variantPrice = 0, overSizedDeliveryFee = 0, additionalDeliveryFee = 0, currentStock } = getItemAccToCollectionType(listing, data);
  // console.log(variantPrice, '&&& &&& => variantPrice');

  return {
    listingId: listing?.id?.uuid,
    images: (Array.isArray(images) && images.length && images[0].attributes?.variants && images[0].attributes?.variants["listing-card"]) ? images[0].attributes?.variants["listing-card"]?.url : "",
    title: productTitle,
    price: variantPrice,
    currency: price?.currency || "NZD",
    authorId: listing?.author?.id?.uuid,
    afterPayFee,
    overSizedDeliveryFee: parseInt(overSizedDeliveryFee),
    additionalDeliveryFee,
    currentStock
  };
}

export const onGetCartItems = (currentUser, nonLoggedUser) => {
  const nonLoggedUserData = typeof window != 'undefined' && window.sessionStorage.getItem('nonLoggedUser');
  const { privateData, publicData } = nonLoggedUser && nonLoggedUser.publicData
    ? nonLoggedUser
    : nonLoggedUserData
      ? JSON.parse(nonLoggedUserData)
      : {};
  const { cartListings = [], saveLaterListings = [] } = currentUser?.attributes?.profile?.publicData || {};

  return {
    cartListings: Array.isArray(cartListings) || Array.isArray(publicData.cartListings)
      ? filterCartListings([...cartListings, ...(publicData && publicData.cartListings ? publicData.cartListings : [])],currentUser)
      : [],
    saveLaterListings: Array.isArray(saveLaterListings) || Array.isArray(publicData.saveLaterListings)
      ? filterCartListings([...saveLaterListings, ...(publicData && publicData.saveLaterListings ? publicData.saveLaterListings : [])],currentUser)
      : [],
    privateData
  }
};


const onGetCartItemValues = (values) => {
  return {
    size: values.size || "",
    customSize: values.customSize || "",
    color: values.color || "",
    customColor: values.customColor || "",
    metal: values.metal || "",
    scent: values.scent || "",
    flavours: values.flavours || "",
    customisation: values.customisation || 0,
    customisationTitle: values.customisationTitle || "",
    overSizedDeliveryFe: values.overSizedDeliveryFe || "",
    quantity: values.quantity || 1,
    shippingOption: values.shippingOption || "0",
    shippingLabel: values.shippingLabel || ""
  };
}

// data = { shippingOption size color quantity customisation price image title , authorId }
export const onGetCartItem = (listing, data) => {
  const addValues = onGetCartItemValues(data)
  const CartListingData = getCartListingData(listing, addValues);
  return { ...addValues, ...CartListingData }
};


export const onGetAuthorCartListings = (listings = [], cartListings = [], checkout) => {
  const authorListings = [];

  // Filter the cart listings that match the listings based on ID
  const filteredCartListings = cartListings.filter(cartItem =>
    listings.some(listing => listing?.id?.uuid === cartItem.listingId)
  );

  // Iterate over the filtered cart listings
  filteredCartListings.forEach(cartListing => {
    const { listingId } = cartListing;
    const matchedListing = listings.find(listing => listingId === listing.id.uuid);

    if (matchedListing) {
      const { author } = matchedListing;
      const updatedData = getCartListingData(matchedListing, cartListing);
      const listingData = {
        ...cartListing,
        ...updatedData,
      };

      // Find if the author is already in the authorListings array
      const existingAuthorIndex = authorListings.findIndex(auth => auth.id.uuid === author?.id?.uuid);

      if (existingAuthorIndex >= 0) {
        // Add the listing to the existing author's listings
        authorListings[existingAuthorIndex].listings.push(listingData);
      } else {
        // Add a new author entry
        authorListings.push({
          ...author,
          listings: [listingData]
        });
      }
    }
  });

  // If checkout is true, apply discount logic and return flattened listings
  if (checkout) {
    return authorListings.map(author => {
      const authorDiscount = onGetFreeShippingDiscount(author);

      if (authorDiscount && author.listings.length) {
        const totalListingPrice = author.listings.reduce((total, listing) => total + (+listing.price * +listing.quantity), 0);

        const updatedListings = totalListingPrice > authorDiscount
          ? author.listings.map(listing => ({ ...listing, shippingOption: 0, additionalDeliveryFee: 0 }))
          : author.listings;

        return { ...author, listings: updatedListings };
      }

      return author;
    }).flatMap(author => author.listings);
  }

  // Return the author listings without modifications if not in checkout mode
  return authorListings;
};


export const onGetAuthorSaveLaterListings = (listings = [], saveLaterListings = []) => {
  // Return empty array if no listings
  if (!listings.length) return [];


  // Filter the cart listings that match the listings based on ID
  const filteredSaveLaterListings = saveLaterListings.filter(cartItem =>
    listings.some(listing => listing?.id?.uuid === cartItem.listingId)
  );

  // Map over listings to construct saveLaterAuthorListings array
  return filteredSaveLaterListings.map(listing => {
    const { listingId } = listing;
    const matchedListing = listings.find(listing => listingId === listing.id.uuid);

    // Get the updated listing data
    const updatedData = getCartListingData(matchedListing, listing);

    // Return the combined data
    return {
      ...listing,
      ...updatedData
    };
  });
};

export const makeFormatPrice = (number = 0) => {
  const formattedPrice = (+number / 100).toFixed(2);
  return `$${formattedPrice}`;
};

export const makeFormatDiscountPrice = (number = 0) => {
  const formattedDiscount = (-(+number) / 100).toFixed(2);
  return `${formattedDiscount} NZD`;
};

export const isMatchingListing = (listingA, listingB) => {
  return allVariantTypes.every(key => listingA[key] ? listingA[key] === listingB[key] : true);
};

// action add , update , remove
export const manageListings = (action, originalListings, newListing) => {
  // Function to check if two listings match based on possibleValues array


  // Find index of the listing based on matching properties
  const findListingIndex = (listings, listingToFind) => {
    return listings.findIndex(listing => listing.listingId == listingToFind.listingId && isMatchingListing(listing, listingToFind));
  };

  switch (action) {
    case ADD_MANAGE_LISTING_TYPE:
      // Check if a listing with matching properties exists
      const addIndex = findListingIndex(originalListings, newListing);
      if (addIndex === -1) {
        // If not found, add new listing
        originalListings.push(newListing);
      } else {
        // If found, update the stock and quantity
        originalListings[addIndex].currentStock += newListing.currentStock;
        originalListings[addIndex].quantity += newListing.quantity;
      }
      break;

    case ADD_MANAGE_LISTING_UPDATE:
      // Find the listing and update it
      const updateIndex = findListingIndex(originalListings, newListing);
      if (updateIndex !== -1) {
        // Update the relevant fields
        originalListings[updateIndex] = { ...originalListings[updateIndex], ...newListing };
      } else {
        console.log("Listing not found for update");
      }
      break;

    case ADD_MANAGE_LISTING_REMOVE:
      // Find the listing and remove it
      const removeIndex = findListingIndex(originalListings, newListing);
      if (removeIndex !== -1) {
        originalListings.splice(removeIndex, 1);
      } else {
        console.log("Listing not found for removal");
      }
      break;

    default:
      console.log("Invalid action");
      break;
  }

  return originalListings;
};

export const listItemPresentOrNot = (listings = [], listing, values = {}) => {
  if (!(Array.isArray(listings) && typeof listing == "object" && (listing?.id?.uuid) && typeof values == "object")) {
    return false;
  };
  return listings.findIndex((st) => st.listingId == listing.id.uuid && allVariantTypes.every((itt) => st[itt] ? st[itt] == values[itt] : true)) >= 0;
};

export const listItemData = (listings = [], listing, values = {}) => {
  if (!(Array.isArray(listings) && typeof listing == "object" && (listing?.id?.uuid) && typeof values == "object")) {
    return false;
  };
  return listings.find((st) => st.listingId == listing.id.uuid && allVariantTypes.every((itt) => st[itt] ? st[itt] == values[itt] : true));
};


// get checkout price of a particular author
export const getCheckoutPrice = (listings) => {
  const subTotal = listings.reduce((acc, curr) => acc + ((+curr.price) * (+curr.quantity)), 0);
  const Delivery = listings.sort((a, b) => (+b.shippingOption) - (+a.shippingOption));
  const customisation = listings.reduce((acc, curr) => acc + (((+curr.customisation) || 0) * ((+curr.quantity) || 0)), 0);
  const discountCode = listings[0].discountCode || "";
  const discount = listings[0].discount || 0;
  const coupounTotalDiscount = discount ? ((+subTotal) * (+discount)) / 100 : 0;
  const additionalDeliveryFee = listings.reduce((acc, curr) => acc + (+curr.additionalDeliveryFee || 0), 0);

  // shippingOption customisation currency quantity 
  return {
    subTotal, customisation, shippingOption: Delivery[0].shippingOption || 0, coupounTotalDiscount: coupounTotalDiscount, discountCode, discount, additionalDeliveryFee,
    total: ((+subTotal) + (+customisation) + (+Delivery[0].shippingOption || 0) + (+additionalDeliveryFee)) - (+coupounTotalDiscount)
  }
}

// get total Checkout Price 
export const getTotalCheckoutPrice = (listings) => {
  const groupedByAuthorId = listings.reduce((acc, listing) => {
    const { authorId } = listing;
    if (!acc[authorId]) {
      acc[authorId] = [];
    }
    acc[authorId].push(listing);
    return acc;
  }, {});

  const uniqueListings = Object.values(groupedByAuthorId).map(group => group[0]);
  const subTotal = listings?.reduce((acc, curr) => acc + ((+curr.price) * (curr.quantity)), 0);
  const subTotalWithDiscount = listings.reduce((acc, curr) => acc + (curr.discount ? (curr.price - ((curr.price * (+curr.discount)) / 100)) * curr.quantity : (+curr.price) * (curr.quantity)), 0);
  const Delivery = listings?.sort((a, b) => (+b.shippingOption) - (+a.shippingOption));
  const customisation = listings?.reduce((acc, curr) => acc + (((+curr.customisation) || 0) * ((+curr.quantity) || 0)), 0);
  const discountCode = listings[0]?.discountCode || "";
  const discount = listings[0]?.discount || 0;
  const coupounTotalDiscount = discount ? ((+subTotal) * (+discount)) / 100 : 0;
  const additionalDeliveryFee = listings?.reduce((acc, curr) => acc + ((+curr.additionalDeliveryFee || 0) * ((+curr.quantity) || 0)), 0);
  const appliedDiscount = listings?.reduce((acc, curr) => acc + (((curr.price * curr.discount / 100) || 0)) * ((+curr.quantity) || 0), 0);
  const shippingFee = uniqueListings.reduce((acc, curr) => acc + (+curr.shippingOption || 0), 0);
  const overSizedDeliveryFee = listings?.reduce((acc, curr) => acc + ((+curr.overSizedDeliveryFee || 0) * ((+curr.quantity) || 0)), 0);

  // shippingOption customisation currency quantity 
  return {
    subTotal,
    customisation,
    appliedDiscount,
    subTotalWithDiscount,
    shippingOption: shippingFee || 0,
    coupounTotalDiscount: makeFormatDiscountPrice(appliedDiscount),
    discountCode,
    discount,
    additionalDeliveryFee,
    total: ((+subTotal) + (+customisation) + (+shippingFee || 0) + (+additionalDeliveryFee)) - (+appliedDiscount),
    totalWithDiscount: (
      (+subTotalWithDiscount || 0) +
      (+customisation || 0) +
      (+shippingFee || 0) +
      (additionalDeliveryFee ? (+additionalDeliveryFee) || 0 : 0) +
      (overSizedDeliveryFee ? ((+overSizedDeliveryFee) * 100) || 0 : 0)
    ),
    totalDiscount: makeFormatPrice(appliedDiscount), overSizedDeliveryFee: +overSizedDeliveryFee * 100
  }
}

export const onMakeTypsenceData = (listing, included, user) => {
  const { firstName, lastName, displayName, publicData: userPublicData } = user.attributes.profile;

  const { id, attributes } = listing;
  const { title, description, price, publicData } = attributes;
  const {
    customisation,
    heroImageId,
    listingType,
    primaryCategory,
    stockDefault,
    subCategory,
    transactionProcessAlias,
    type,
    unitType,
    variantColor,
    variantSize,
    variants,
    additionalDeliveryFee,
    ruralShipping,
    shippingPriceInSubunitsOneItem,
    shippingDiscount,
    pickupEnabled,
    shippingEnabled
  } = publicData;

  const imageURL = included && included.length ? included.filter((st) => st.type == "image" && st.attributes?.variants && st.attributes?.variants["listing-card"] && st.attributes.variants["listing-card"].url).map((st) => st.attributes.variants["listing-card"].url) : [];

  return {
    "id": id.uuid,
    "title": title,
    "description": description || "",
    "listingType": listingType || "",
    "transactionProcessAlias": transactionProcessAlias || "",
    "unitType": unitType || "",
    "subCategory": subCategory || "",
    "primaryCategory": primaryCategory || "",
    "user": {
      userId: user.id.uuid, firstName, lastName, email: user.attributes.email, displayName
    },
    "variants": variants || [],
    // [
    //   {
    //     "key": "value1"
    //   },
    //   {
    //     "key": "value2"
    //   }
    // ],
    "variantColor": variantColor || [],
    // [
    //   "String Value 1 ",
    //   "String Value 2"
    // ],
    "variantSize": variantSize || [],
    // [
    //   "String Value 1 ",
    //   "String Value 2"
    // ],
    "customisation": customisation || [],
    // [
    //   {
    //     "key": "value1"
    //   },
    //   {
    //     "key": "value2"
    //   }
    // ],
    "heroImageId": heroImageId || "",
    "imageURL": imageURL,
    // [
    //   "String Value 1 ",
    //   "String Value 2"
    // ],
    "shippingDiscount": shippingDiscount || [],
    // [
    //   {
    //     "key": "value1"
    //   },
    //   {
    //     "key": "value2"
    //   }
    // ],
    "price": price.amount / 100,
    "priceCurrency": price.currency,
    "ruralShipping": ruralShipping || 0,
    "shippingPriceInSubunitsOneItem": shippingPriceInSubunitsOneItem || 0,
    "pickupEnabled": pickupEnabled || false,
    "shippingEnabled": shippingEnabled || false,
    "type": type || "",
    "stockDefault": stockDefault || 0,
    "additionalDeliveryFee": additionalDeliveryFee || 0
  };

};

// check valid sellers
export const checkValidSeller = (currentUser) => {
  return currentUser?.attributes?.profile?.protectedData?.Seller ? true : false;
};

// check seller status 
export const checkValidSellerStatus = (currentUser) => {
  return currentUser?.attributes?.profile?.protectedData?.seller_approved_status ? true : false;
};

// check member Ship of User 
export const checkMemberShipSeller = (currentUser) => {
  return currentUser?.attributes?.profile?.protectedData?.Membership ? true : false;
}



// get total Price of particular author listings 
export const getTotalPrice = (listings = [], authorDiscount = 0) => {
  if (listings.length === 0) {
    return { subTotal: 0, customization: 0, delivery: 0, coupounApplied: false };
  }

  const subTotal = listings.reduce((acc, curr) =>
    acc + (curr.discount ?
      (curr.price - ((curr.price * (+curr.discount)) / 100)) * curr.quantity :
      (+curr.price) * (curr.quantity)), 0);

  const sortedListings = [...listings].sort((a, b) => (+b.shippingOption) - (+a.shippingOption));
  const customization = listings.reduce((acc, curr) => acc + ((+curr.customisation) * (+curr.quantity)), 0);

  const delivery = authorDiscount && (subTotal / 100) > authorDiscount ? 0 : (sortedListings[0].shippingOption || 0);
  const coupounApplied = listings.findIndex((st) => st.discount) >= 0;

  return { subTotal, customization, delivery, coupounApplied };
};

// get total Stock of Listings 
export const getTotalDefaultStockOfListing = (listing) => {
  const { stockDefault = 0, variants } = listing?.attributes?.publicData || {};
  const variantsStock = variants && variants.length ? variants.reduce((acc, curr) => acc + (+(curr.quantity) || 0), 0) : 0;
  return (stockDefault ? (+stockDefault) : 0) + variantsStock;
};

// followingUser == currentUser followerUser== which we follow 
export const makeFollowData = (followingUser, followerUser) => {
  const { id: followingId, attributes: followingAttributes } = followingUser;
  const { displayName: followigDisplayName } = followingAttributes.profile;
  const { id, attributes } = followerUser;
  const { displayName } = attributes.profile;
  return {
    followingId: followingId.uuid,
    followingName: followigDisplayName,
    followerId: id.uuid,
    followerName: displayName,
    isFollow: "2",
    upComingMailSendAt: moment().unix(),
    lastListingPublished: 1
  };
};

// get free shipping price 
export const onGetFreeShippingDiscount = (store) => {
  if (store?.attributes?.profile?.publicData?.discount && store?.attributes?.profile?.publicData?.discount == "Yes") {
    const { discountType } = store.attributes.profile.publicData;
    const freeShippingIndex = freeShipping.findIndex((st) => st.option == discountType);
    return (freeShippingIndex > -1) ? freeShipping[freeShippingIndex].price : 0;
  } else {
    return 0;
  }
};

// get subcategory from childs  
export const onGetSubCategoryChilds = (primaryCategory, subCategory, type) => {
  try {
    const subCategoryChildColorIndex = primaryCategory && subCategory && type ? subCategoryChild.findIndex((st) => st.option == type && `${primaryCategory}-${subCategory}` == st.parent) : primaryCategory && type ? subCategoryChild.findIndex((st) => st.option == type && `${primaryCategory}` == st.parent) : -1;
    return subCategoryChildColorIndex >= 0 ? {
      childs: subCategoryChild[subCategoryChildColorIndex].childs,
      fieldType: subCategoryChild[subCategoryChildColorIndex].fieldType || "",
    }
      : { childs: false, fieldType: "" };
  } catch (e) {
    return { childs: false, fieldType: "" };
  }
};

export const truncateLabel = (label, maxLength = 20) => {
  if (!label) return '';
  return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
};

// get review of author 
export const onGetAuthorReviews = (reviews) => {
  const reviewsOfProvider = Array.isArray(reviews) && reviews.length ? reviews.filter(r => r?.attributes?.type === REVIEW_TYPE_OF_PROVIDER) : [];
  const reviewRating = Array.isArray(reviewsOfProvider) && reviewsOfProvider.length
    ? (reviewsOfProvider.reduce((acc, curr) => acc + (curr.attributes.rating || 0), 0)) / reviewsOfProvider.length
    : 5;
  return { reviewsOfProvider, reviewRating: typeof reviewRating == "number" ? Math.round(reviewRating) : 5 }
};

// to get the exact price 
export const getExactPrice = (price) => {
  const roundFigure = Math.round(+price * 100) / 100;
  return roundFigure;
};

export const onGetUserDetails = (user) => {
  const { email, profile } = user.attributes;
  const { firstName, lastName, displayName, ...rest } = profile;
  return {
    userId: user.id.uuid,
    name: `${firstName} ${lastName}`,
    email,
    firstName,
    lastName,
    displayName,
    ...rest,
  };
};


// <<<<<<<<<<<<<<<< create and update the listing data in algolia >>>>>>>>>>
export const makeCreateUpdateAlgoliaData = (indexName, listing, currentUser, updateParams) => {
  const { name, email, firstName, lastName, displayName, userId, publicData: uPublicData } = onGetUserDetails(currentUser);
  const { storeName } = uPublicData;
  const listingId = listing.id.uuid;
  const { included = [] } = listing
  const {
    title = '',
    description = '',
    price,
    geolocation,
    state = '',
    publicData = {},
    createdAt
  } = listing.attributes || {};
  const lisingImages = [];
  
  const { heroImageId } = publicData;
  const images =  Array.isArray(included) ? included.filter((st) => st.type == SHARETRIBE_TYPE_IMAGE) : [];
  const imageId = images.length && images[0].id.uuid;
  const allImages = images.filter((st) => heroImageId ? st.id.uuid==heroImageId : st.id.uuid == imageId);


  Array.isArray(allImages) && allImages.forEach((st) => st?.attributes?.variants && st?.attributes?.variants[LISTING_CARD_2X]?.url ? lisingImages.push(st?.attributes?.variants[LISTING_CARD_2X]?.url) : null);

  const data = {
    objectID: listingId,
    _geoloc: geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
    title,
    description,
    state,
    price: price && price.currency && price.amount ? (price.amount / 100) : 0,
    currency: price ? price.currency : null,
    userId,
    lisingImages,
    user: { name, email, firstName, lastName, displayName, userId, publicData: { storeName } },
    publicData,
    createdAt: moment(createdAt).unix(),
  };

  return {
    indexName,
    data: updateParams ? { ...updateParams, objectID: listingId, } : data
  };
};


// on get algolia data 
export const onGetEventAlgoliaData = (currentUser, listing, filters) => {
  const { id } = currentUser;
  const { objectID, user } = listing;
  const { storeName } = user.publicData || {};
  const menu = typeof filters == "object" ? filters : null;
  return {
    userToken: id.uuid,
    objectIDs: [objectID],
    eventData: {
      "UserID": id.uuid,
      "ListingID": objectID,
      "Business Name": storeName,
      "Collection": menu.collection || "",
      "Sub-Collection": menu.subCollection || "",
      "Type": menu.type || "",
      "Size": menu.size || "",
      "Colour": menu.color || "",
      "Metal": menu.metal || "",
      "Scent": menu.scent || "",
      "Flavour": menu.flavours || ""
    }
  }
};

// {
//   "RITA_DEV": {
//       "configure": {
//           "filters": "state:\"published\" AND (publicData.totalStockAvailable > 0)",
//           "ruleContexts": [],
//           "hitsPerPage": 12
//       },
//       "query": "bus",
//       "menu": {
//           "publicData.primaryCategory": "Pēpi",
//           "publicData.subCategory": "Wear"
//       },
//       "sortBy": "RITA_DEV_price_asc",
//       "range": {
//           "price": "1:15"
//       }
//   }
// }




export const makeAlgoliaSearchParams = (indexName, value) => {
  const searchParams = {};
  const { menu, query, sortBy, range, page, refinementList, toggle } = typeof value == "object" ? value[indexName] : {};

  // search query 
  query && Object.assign(searchParams, { query: query });

  // page query 
  page && Object.assign(searchParams, { page: page });

  // sortBy 
  sortBy && algoliaSortOptions.filter((st) => st.value == sortBy).length
    && Object.assign(searchParams, { sort: algoliaSortOptions.filter((st) => st.value == sortBy)[0].searchKeyName });

  // price price "10:11"
  typeof range == "object" && range.price && range.price.includes(":")
    && Object.assign(searchParams, { price: range.price.split(":")[0] + "-" + range.price.split(":")[1] });

  // menu filters
  typeof menu == "object" && Object.keys(menu).length && Object.keys(menu).map((st) => SEARCH_KEY_PARAMS[st] && Object.assign(searchParams, {
    [SEARCH_KEY_PARAMS[st]]: menu[st]
  }));

  typeof refinementList == "object" && Object.keys(refinementList).length && Object.keys(refinementList).map((st) => SEARCH_KEY_PARAMS[st] && Array.isArray(refinementList[st]) && Object.assign(searchParams, {
    [SEARCH_KEY_PARAMS[st]]: refinementList[st].join(",")
  }));

  // "theEdit":"publicData.theEdit",
  typeof toggle == "object" && toggle["publicData.theEdit"] && Object.assign(searchParams, { "theEdit": toggle["publicData.theEdit"] });

  // "businessCollection":"publicData.businessCollection"
  typeof toggle == "object" && toggle["publicData.businessCollection"] && Object.assign(searchParams, { "businessCollection": toggle["publicData.businessCollection"] });

  return searchParams;
};

export const setAlgoliaInitailState = (indexName, value, configureProps = false, isSearchPage) => {
  const initialState = {
    [indexName]: {
      "configure": configureProps ? configureProps : {
        "filters": "state:\"published\" AND (publicData.totalStockAvailable > 0)",
        "ruleContexts": [],
        "hitsPerPage": 12
      },
      refinementList: {

      },
      menu: {

      },
      toggle: {

      }
    }
  };

  const {
    sort,
    price,
    query,
    page,
    theEdit,
    businessCollection,
    ...rest
  } = typeof value == "object" ? value : {};

  // search query 
  query && Object.assign(initialState[indexName], { query });

  // search query 
  page && Object.assign(initialState[indexName], { page });

  // sortBy 
  sort && algoliaSortOptions.filter((st) => st.searchKeyName == sort).length
    && Object.assign(initialState[indexName], { sortBy: algoliaSortOptions.filter((st) => st.searchKeyName == sort)[0].value });

  // price
  const newPrice = price ? price + "" : "";
  newPrice && Object.assign(initialState[indexName], { range: { price: newPrice.includes("-") ? newPrice.split("-")[0] + ":" + newPrice.split("-")[1] : newPrice } });

  // all menu filters
  isSearchPage && typeof rest == "object" && Object.keys(rest).length
    && Object.keys(rest).map((st) => ALGOLIA_SEARCH_KEY_PARAMS[st] && Object.assign(initialState[indexName].refinementList, { [ALGOLIA_SEARCH_KEY_PARAMS[st]]: rest[st].split(",") }));

  // "theEdit":"publicData.theEdit",
  theEdit && Object.assign(initialState[indexName].toggle, { "publicData.theEdit": theEdit })

  // "businessCollection":"publicData.businessCollection"
  businessCollection && Object.assign(initialState[indexName].toggle, { "publicData.businessCollection": businessCollection })

  // all menu filters
  !isSearchPage && typeof rest == "object" && Object.keys(rest).length
    && Object.keys(rest).map((st) => ALGOLIA_SEARCH_KEY_PARAMS[st] && Object.assign(initialState[indexName].menu, { [ALGOLIA_SEARCH_KEY_PARAMS[st]]: rest[st] }));

  return initialState;
}


export const getAllPurchasedVariants = (transactions) => {
  const newArray = [];
  transactions.forEach((st) => {
    const { eachCartItem } = st?.attributes?.protectedData || {};
    typeof eachCartItem == "object" ? newArray.push(eachCartItem) : null;
  });
  return newArray
};

export const checkValuePresentInVariants = (key, value, varaints) => {
  return Array.isArray(varaints) && varaints.filter((st) => value && st[key] == value).length ? true : false;
}


export const filterCartListings = (arr,currentUser) => {
  const findListingIndex = (listings, listingToFind) => {
    return listings.findIndex(listing => listing.listingId == listingToFind.listingId && isMatchingListing(listing, listingToFind));
  };
  return Array.isArray(arr) ? arr.filter((value, index, self) =>
    index === findListingIndex(self, value)
  ).filter((st)=> st.authorId != currentUser?.id?.uuid) : [];
};