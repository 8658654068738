/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

export const SINGLE_PRODUCT_COLLECTION="This is a single product";

export const SingleVariantCollection = [
  "This product has different colours",
  "This product has different sizes",
  "This product has different scent",
  "This product has different metals",
  "This product has different flavours"
];

export const DoubleVariantCollection = [
  "This product has different sizes and colours",
  "This product has different sizes and metals",
  "This product has different sizes and scent",
  "This product has different sizes and flavours"
];

const allCollectionDropdowns = [
  "This is a single product",
  "This product has different colours",
  "This product has different sizes",
  "This product has different scent",
  "This product has different metals",
  "This product has different flavours",
  "This product has different sizes and colours",
  "This product has different sizes and metals",
  "This product has different sizes and scent",
  "This product has different sizes and flavours"
];

export const priorityShowTypes = ["metal","size","customSize","color","customColor","flavours","scent"];

export const expectionCollectionValuesCheck=[{key:"color",value:"customColor"},{key:"size",value:"customSize"}];
export const collectionValuesCheckVariants = [
  {key:"This product has different colours", checkValuesArray:["color"] },
  {key:"This product has different sizes", checkValuesArray:["size"] },
  {key:"This product has different scent", checkValuesArray:["scent"] },
  {key:"This product has different metals", checkValuesArray:["metal"] },
  {key:"This product has different flavours", checkValuesArray:["flavours"] },
  {key:"This product has different sizes and colours", checkValuesArray:["size","color"] },
  {key:"This product has different sizes and metals", checkValuesArray:["metal","size"] },
  {key:"This product has different sizes and scent", checkValuesArray:["scent","size"] },
  {key:"This product has different sizes and flavours", checkValuesArray:["flavours","size"] },
];

export const collectionDropdowns = [
  { option: "This is a single product", label: "This is a single product" },
  { option: "This product has different colours", label: "This product has different colours" },
  { option: "This product has different sizes", label: "This product has different sizes" },
  { option: "This product has different sizes and colours", label: "This product has different sizes and colours" },
];

export const collectionDropdownsMetal = [
  { option: "This is a single product", label: "This is a single product" },
  { option: "This product has different metals", label: "This product has different metals" },
  { option: "This product has different sizes", label: "This product has different sizes" },
  { option: "This product has different sizes and metals", label: "This product has different sizes and metals" }
];

export const collectionDropdownsFlavours = [
  { option: "This is a single product", label: "This is a single product" },
  { option: "This product has different flavours", label: "This product has different flavours" },
  { option: "This product has different sizes", label: "This product has different sizes" },
  { option: "This product has different sizes and flavours", label: "This product has different sizes and flavours" }
];

export const collectionDropdownsScent = [
  { option: "This is a single product", label: "This is a single product" },
  { option: "This product has different scent", label: "This product has different scent" },
  { option: "This product has different sizes", label: "This product has different sizes" },
  { option: "This product has different sizes and scent", label: "This product has different sizes and scent" }
]

export const collectionDropdownsSingle = [{ option: "This is a single product", label: "This is a single product" }, { option: "This product has different sizes", label: "This product has different sizes" }];

export const collectionDropdownsOne = [{ option: "This is a single product", label: "This is a single product" }];

export const primaryCategory = [
  { option: "Pēpi", label: "Pēpi" },
  { option: "Kids", label: "Kids" },
  { option: "Wear", label: "Wear" },
  { option: "Jewellery", label: "Jewellery" },
  { option: "Lifestyle & Beauty", label: "Lifestyle & Beauty" },
  { option: "Home", label: "Home" },
  { option: "Art", label: "Art" },
  { option: "Pantry", label: "Pantry", collectionDropdowns: collectionDropdownsFlavours },
  { option: "Furniture", label: "Furniture", collectionDropdowns: collectionDropdownsSingle },
  { option: "Stationery & Games", label: "Stationery & Games" },
  { option: "Pakihi Māori", label: "Pakihi Māori" },
  { option: "The Edit", label: "The Edit" },
];


export const subCategory = [
  // Pēpi
  { option: 'Wear', label: 'Wear', parent: "Pēpi", collectionDropdowns: collectionDropdowns },
  {
    option: 'Care',
    label: 'Care',
    parent: "Pēpi",
    collectionDropdowns: collectionDropdownsSingle
  },
  { option: 'Play', label: 'Play', parent: "Pēpi", collectionDropdowns: collectionDropdowns },
  // { option: 'Room', label: 'Room', parent: "Pēpi" },
  { option: 'Room & Sleep', label: 'Room & Sleep', parent: "Pēpi", collectionDropdowns: collectionDropdowns },
  { option: 'For Mum', label: 'For Mum', parent: "Pēpi", collectionDropdowns: collectionDropdowns },

  // Kids
  { option: 'Wear', label: 'Wear', parent: "Kids", collectionDropdowns: collectionDropdowns },
  { option: 'Play', label: 'Play', parent: "Kids", collectionDropdowns: collectionDropdowns },
  { option: 'Room & Sleep', label: 'Room & Sleep', parent: "Kids", collectionDropdowns: collectionDropdowns },
  { option: 'Accessories', label: 'Accessories', parent: "Kids", collectionDropdowns: collectionDropdowns },

  // Wear
  { option: 'Womens', label: 'Womens', parent: "Wear", collectionDropdowns: collectionDropdowns },
  { option: 'Mens', label: 'Mens', parent: "Wear", collectionDropdowns: collectionDropdowns },
  // { option: 'Kids', label: 'Kids', parent: "Wear", collectionDropdowns: collectionDropdowns },
  // { option: 'Pēpi', label: 'Pēpi', parent: "Wear", collectionDropdowns: collectionDropdowns },
  { option: 'Accessories', label: 'Accessories', parent: "Wear", collectionDropdowns: collectionDropdowns },

  // Jewellery
  { option: 'Ears', label: 'Ears', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },
  { option: 'Fingers', label: 'Fingers', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },
  { option: 'Necks', label: 'Necks', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },
  { option: 'Wrist', label: 'Wrist', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },
  // { option: 'Ankles', label: 'Ankles', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },/
  { option: 'Other', label: 'Other', parent: "Jewellery", collectionDropdowns: collectionDropdownsMetal },

  // Lifestyle & Beauty
  { option: 'Beauty & Body', label: 'Beauty & Body', parent: "Lifestyle & Beauty", collectionDropdowns: collectionDropdownsScent },
  { option: 'Fragrance', label: 'Fragrance', parent: "Lifestyle & Beauty", collectionDropdowns: collectionDropdownsScent },
  // { option: 'Paper Goods & Stationery', label: 'Paper Goods & Stationery', parent: "Lifestyle & Beauty", collectionDropdowns: collectionDropdownsScent },
  { option: 'Wellness & Rituals', label: 'Wellness & Rituals', parent: "Lifestyle & Beauty", collectionDropdowns: collectionDropdowns },

  // Home
  { option: 'Kitchen', label: 'Kitchen', parent: "Home", collectionDropdowns: collectionDropdowns },
  // { option: 'Kitchen & Table', label: 'Kitchen & Table', parent: "Home" },
  { option: 'Bed', label: 'Bed', parent: "Home", collectionDropdowns: collectionDropdowns },
  { option: 'Ceramics', label: 'Ceramics', parent: "Home", collectionDropdowns: collectionDropdowns },
  { option: 'Outdoor & Garden', label: 'Outdoor & Garden', parent: "Home", collectionDropdowns: collectionDropdowns },
  // { option: 'Bath', label: 'Bath', parent: "Home" },
  // { option: 'Outdoor', label: 'Outdoor', parent: "Home" },
  { option: 'Candles', label: 'Candles', parent: "Home", collectionDropdowns: collectionDropdownsScent },
  { option: 'Decor', label: 'Decor', parent: "Home", collectionDropdowns: collectionDropdowns },
  { option: 'Cleaning', label: 'Cleaning', parent: "Home", collectionDropdowns: collectionDropdowns },
  // { option: 'Cleaning Products', label: 'Cleaning Products', parent: "Home" },
  { option: 'Pets', label: 'Pets', parent: "Home", collectionDropdowns: collectionDropdowns },
  // { option: 'Furniture', label: 'Furniture', parent: "Home" },

  // Art
  { option: 'Prints', label: 'Prints', parent: "Art", collectionDropdowns: collectionDropdowns },
  {
    option: 'Fine Art Photography',
    label: 'Fine Art Photography',
    parent: "Art",
    collectionDropdowns: collectionDropdownsSingle
  },
  { option: 'Originals', label: 'Originals', parent: "Art", collectionDropdowns: collectionDropdownsOne },
  { option: 'Sculpture & Wall Hanging', label: 'Sculpture & Wall Hanging', parent: "Art", collectionDropdowns: collectionDropdownsOne },

  // // Pantry
  // { option: 'Kai', label: 'Kai', parent: "Pantry" },
  // { option: 'Inu', label: 'Inu', parent: "Pantry" },

  // Pakihi Māori
  // { option: '', label: '', parent: "Pakihi Māori" },

  // The Edit
  // { option: '', label: '', parent: "The Edit" },

  // Stationery & Games
  { option: 'Cards', label: 'Cards', parent: "Stationery & Games", collectionDropdowns: collectionDropdownsOne },
  { option: 'Wrap', label: 'Wrap', parent: "Stationery & Games", collectionDropdowns: collectionDropdownsSingle },
  { option: 'Planners & Calenders', label: 'Planners & Calenders', parent: "Stationery & Games", collectionDropdowns: collectionDropdowns },
  { option: 'Journals & Notebooks', label: 'Journals & Notebooks', parent: "Stationery & Games", collectionDropdowns: collectionDropdowns },
  { option: 'Games & Puzzles', label: 'Games & Puzzles', parent: "Stationery & Games", collectionDropdowns: collectionDropdownsOne },
  { option: 'Other', label: 'Other', parent: "Stationery & Games", collectionDropdowns: collectionDropdowns },
  
];

export const CUSTOM_COLOUR_LENGTH = 30;

const Colours = [
  { option: 'Beige', label: 'Beige' },
  { option: 'White', label: 'White' },
  { option: 'Brown', label: 'Brown' },
  { option: 'Black', label: 'Black' },
  { option: 'Blue', label: 'Blue' },
  { option: 'Red', label: 'Red' },
  { option: 'Yellow', label: 'Yellow' },
  { option: 'Green', label: 'Green' },
  { option: 'Purple', label: 'Purple' },
  { option: 'Pink', label: 'Pink' },
  { option: 'Orange', label: 'Orange' },
  { option: 'Floral', label: 'Floral' },
  { option: 'Multi', label: 'Multi' },
  { option: 'Grey', label: 'Grey' },
  { option: 'Custom', label: 'Custom' },
];

const PEPE_WEAR_TYPE = [
  { option: 'Onesie', label: 'Onesie' },
  { option: 'Pants & Shorts', label: 'Pants & Shorts' },
  { option: 'Rompers & Overalls', label: 'Rompers & Overalls' },
  { option: 'Tops', label: 'Tops' },
  // { option: 'Pants', label: 'Pants' },
  // { option: 'Top', label: 'Top' },
  { option: 'Footwear', label: 'Footwear' },
  // { option: 'Dress', label: 'Dress' },
  { option: 'Dresses & Skirts', label: 'Dresses & Skirts' },
  // { option: 'Skirt', label: 'Skirt' },
  { option: 'Outerwear', label: 'Outerwear' },
  // { option: 'Hat', label: 'Hat' },
  { option: 'Hats', label: 'Hats' },
  // { option: 'Shoes', label: 'Shoes' },
  { option: 'Accessories', label: 'Accessories' },
  { option: 'Sleepwear', label: 'Sleepwear' },
  { option: 'Underwear', label: 'Underwear' },
  { option: 'Other', label: 'Other' },
  // Other 
];

const KIDS_WEAR_TYPE = [
  // { option: 'Onesie', label: 'Onesie' },
  { option: 'Pants & Shorts', label: 'Pants & Shorts' },
  // { option: 'Rompers & Overalls', label: 'Rompers & Overalls' },
  { option: 'Tops', label: 'Tops' },
  // { option: 'Pants', label: 'Pants' },
  // { option: 'Top', label: 'Top' },
  // { option: 'Footwear', label: 'Footwear' },
  // { option: 'Dress', label: 'Dress' },
  { option: 'Dresses & Skirts', label: 'Dresses & Skirts' },
  // { option: 'Skirt', label: 'Skirt' },
  { option: 'Outerwear', label: 'Outerwear' },
  { option: 'Hat', label: 'Hat' },
  // { option: 'Hats', label: 'Hats' },
  // { option: 'Shoes', label: 'Shoes' },
  { option: 'Accessories', label: 'Accessories' },
  { option: 'Sleepwear', label: 'Sleepwear' },
  // { option: 'Underwear', label: 'Underwear' },
  { option: 'Other', label: 'Other' },
  // Other 
];

const PEPE_WEAR_SIZE = [
  { option: 'Prem', label: 'Prem' },
  { option: 'Newborn', label: 'Newborn' },
  { option: '0-3 months', label: '0-3 months' },
  { option: '3-6 months', label: '3-6 months' },
  { option: '0-6 months', label: '0-6 months' },
  { option: '6-12 months', label: '6-12 months' },
  { option: '9-12 months', label: '9-12 months' },
  { option: '12-18 months', label: '12-18 months' },
  { option: '1 year', label: '1 year' },
  { option: '2 year', label: '2 year' },
  { option: '3 year', label: '3 year' },
  { option: '4 year', label: '4 year' },
  { option: '5 year', label: '5 year' },
  { option: 'One size fits all ', label: 'One size fits all ' },
  { option: 'Small', label: 'Small' },
  { option: 'Medium', label: 'Medium' },
  { option: 'Large', label: 'Large' },
];

const KIDS_WEAR_SIZE = [
  // { option: '1 year', label: '1 year' },
  // { option: '2 year', label: '2 year' },
  { option: '3 year', label: '3 year' },
  { option: '4 year', label: '4 year' },
  { option: '5 year', label: '5 year' },
  { option: '6 year', label: '6 year' },
  { option: '7 year', label: '7 year' },
  { option: '8 year', label: '8 year' },
  { option: '9 year', label: '9 year' },
  { option: '10 year', label: '10 year' },
  { option: '11 year', label: '11 year' },
  { option: '12 year', label: '12 year' },
  { option: '14 year', label: '14 year' },
  { option: 'One size fits all ', label: 'One size fits all ' },
  { option: 'Small', label: 'Small' },
  { option: 'Medium', label: 'Medium' },
  { option: 'Large', label: 'Large' },
];

const WEAR_WOMENS_TYPE = [
  // { option: 'Pants', label: 'Pants' },
  // Dresses & Skirts
  { option: 'Pants & Shorts', label: 'Pants & Shorts' },
  { option: 'Dresses & Skirts', label: 'Dresses & Skirts' },
  // { option: 'Skirt', label: 'Skirt' },
  // { option: 'Dress', label: 'Dress' },
  { option: 'Tops', label: 'Tops' },
  // { option: 'Shorts', label: 'Shorts' },
  // { option: 'Top', label: 'Top' },
  { option: 'Sweatshirt', label: 'Sweatshirt' },
  { option: 'Outerwear', label: 'Outerwear' },
  { option: 'Underwear', label: 'Underwear' },
  { option: 'Sleepwear', label: 'Sleepwear' },
  { option: 'Accessories', label: 'Accessories' },
  // { option: 'Socks', label: 'Socks' },
  // Accessories
];

const WEAR_WOMENS_SIZES = [
  { option: 'X Small', label: 'X Small' },
  { option: 'Small', label: 'Small' },
  { option: 'Medium', label: 'Medium' },
  { option: 'Large', label: 'Large' },
  { option: 'X Large', label: 'X Large' },
  { option: 'XX L', label: 'XX L' },
  { option: 'XXX L ', label: 'XXX L ' },
  { option: '4XL', label: '4XL' },
  { option: '5XL', label: '5XL' },
  { option: '6XL', label: '6XL' },
  { option: '7XL', label: '7XL' },
  { option: 'One size fits all', label: 'One size fits all' },
  { option: '8', label: '8' },
  { option: '10', label: '10' },
  { option: '12', label: '12' },
  { option: '14', label: '14' },
  { option: '16', label: '16' },
  { option: '18', label: '18' },
  { option: '20', label: '20' },
  { option: '22', label: '22' },
  { option: 'Other', label: 'Other' },
];

const WEAR_MENS_TYPE = [
  // { option: 'Pants', label: 'Pants' },
  { option: 'Pants & Shorts', label: 'Pants & Shorts' },
  // { option: 'Shorts', label: 'Shorts' },
  // { option: 'Top', label: 'Top' },
  { option: 'Tops', label: 'Tops' },
  { option: 'Sweatshirts', label: 'Sweatshirts' },
  { option: 'Outerwear', label: 'Outerwear' },
  // { option: 'Socks', label: 'Socks' },
  { option: 'Accessories', label: 'Accessories' },
];

const WEAR_MENS_SIZE = [
  { option: 'X Small', label: 'X Small' },
  { option: 'Small', label: 'Small' },
  { option: 'Medium', label: 'Medium' },
  { option: 'Large', label: 'Large' },
  { option: 'X Large', label: 'X Large' },
  { option: 'XX L', label: 'XX L' },
  { option: 'XXX L ', label: 'XXX L ' },
  { option: '4XL', label: '4XL' },
  { option: '5XL', label: '5XL' },
  { option: '6XL', label: '6XL' },
  { option: '7XL', label: '7XL' },
  { option: 'One size fits all', label: 'One size fits all' },
];

export const subCategoryChild = [
  // Pēpi-Wear
  {
    option: 'Type',
    label: 'Type',
    parent: "Pēpi-Wear",
    childs: PEPE_WEAR_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Pēpi-Wear",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Pēpi-Wear",
    childs: PEPE_WEAR_SIZE
  },
  // Pēpi-Care
  {
    option: 'Size',
    label: 'Size',
    parent: "Pēpi-Care",
    fieldType: "text",
    childs: []
  },
  // Pēpi-Play
  {
    option: 'Size',
    label: 'Size',
    parent: "Pēpi-Play",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Pēpi-Play",
    childs: Colours
  },

  //  "Pēpi-Room & Sleep"
  {
    option: 'Size',
    label: 'Size',
    parent: "Pēpi-Room & Sleep",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Pēpi-Room & Sleep",
    childs: Colours
  },

  // "Pēpi-For Mum"
  {
    option: 'Size',
    label: 'Size',
    parent: "Pēpi-For Mum",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Pēpi-For Mum",
    childs: Colours
  },

  // Kids-Wear
  {
    option: 'Type',
    label: 'Type',
    parent: "Kids-Wear",
    childs: KIDS_WEAR_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Kids-Wear",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Kids-Wear",
    childs: KIDS_WEAR_SIZE
  },

  // Kids-Play

  {
    option: 'Size',
    label: 'Size',
    parent: "Kids-Play",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Kids-Play",
    childs: Colours
  },

  // Kids-Room & Sleep 
  {
    option: 'Size',
    label: 'Size',
    parent: "Kids-Room & Sleep",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Kids-Room & Sleep",
    childs: Colours
  },

  //  Kids-Accessories
  {
    option: 'Size',
    label: 'Size',
    parent: "Kids-Accessories",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Kids-Accessories",
    childs: Colours
  },


  // Wear-Womens
  {
    option: 'Type',
    label: 'Type',
    parent: "Wear-Womens",
    childs: WEAR_WOMENS_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Wear-Womens",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Wear-Womens",
    childs: WEAR_WOMENS_SIZES
  },

  // Wear-Mens
  {
    option: 'Type',
    label: 'Type',
    parent: "Wear-Mens",
    childs: WEAR_MENS_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Wear-Mens",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Wear-Mens",
    childs: WEAR_MENS_SIZE
  },

  // Wear-Kids

  {
    option: 'Type',
    label: 'Type',
    parent: "Wear-Kids",
    childs: PEPE_WEAR_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Wear-Kids",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Wear-Kids",
    childs: KIDS_WEAR_SIZE
  },

  // Wear-Pēpi

  {
    option: 'Type',
    label: 'Type',
    parent: "Wear-Pēpi",
    childs: PEPE_WEAR_TYPE
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Wear-Pēpi",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Wear-Pēpi",
    childs: PEPE_WEAR_SIZE
  },


  // Wear-Accessories 
  {
    option: 'Type',
    label: 'Type',
    parent: "Wear-Accessories",
    childs: [
      { option: "Bags & Wallets", label: "Bags & Wallets" },
      { option: 'Hats', label: 'Hats' },
      { option: 'Hair', label: 'Hair' },
      { option: 'Other', label: 'Other' },
    ]
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Wear-Accessories",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Wear-Accessories",
    fieldType: "text",
    childs: Colours
  },

  // Jewellery-Ears
  {
    option: 'Metal',
    label: 'Metal',
    parent: "Jewellery-Ears",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Jewellery-Ears",
    fieldType: "text",
    childs: []
  },

  // Jewellery-Fingers
  {
    option: 'Metal',
    label: 'Metal',
    parent: "Jewellery-Fingers",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Jewellery-Fingers",
    fieldType: "text",
    childs: []
  },

  // Jewellery-Necks
  {
    option: 'Metal',
    label: 'Metal',
    parent: "Jewellery-Necks",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Jewellery-Necks",
    fieldType: "text",
    childs: []
  },

  // Jewellery-Wrist
  {
    option: 'Metal',
    label: 'Metal',
    parent: "Jewellery-Wrist",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Jewellery-Wrist",
    fieldType: "text",
    childs: []
  },

  // Jewellery-Wrist
  {
    option: 'Metal',
    label: 'Metal',
    parent: "Jewellery-Wrist",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Jewellery-Wrist",
    fieldType: "text",
    childs: []
  },

  // Lifestyle & Beauty-Beauty & Body
  {
    option: 'Type',
    label: 'Type',
    parent: "Lifestyle & Beauty-Beauty & Body",
    childs: [
      { option: 'For Her', label: 'For Her' },
      { option: 'For Him', label: 'For Him' },
      { option: 'For All', label: 'For All' },
    ]
  },

  {
    option: 'Scent',
    label: 'Scent',
    parent: "Lifestyle & Beauty-Beauty & Body",
    fieldType: "text",
    childs: []
  },

  {
    option: 'Size',
    label: 'Size',
    parent: "Lifestyle & Beauty-Beauty & Body",
    fieldType: "text",
    childs: []
  },

  // Lifestyle & Beauty-Fragrance
  {
    option: 'Scent',
    label: 'Scent',
    parent: "Lifestyle & Beauty-Fragrance",
    fieldType: "text",
    childs: []
  },

  {
    option: 'Size',
    label: 'Size',
    parent: "Lifestyle & Beauty-Fragrance",
    fieldType: "text",
    childs: []
  },

  // Lifestyle & Beauty-Wellness & Rituals
  {
    option: 'Scent',
    label: 'Scent',
    parent: "Lifestyle & Beauty-Wellness & Rituals",
    fieldType: "text",
    childs: []
  },

  {
    option: 'Size',
    label: 'Size',
    parent: "Lifestyle & Beauty-Wellness & Rituals",
    fieldType: "text",
    childs: []
  },

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Lifestyle & Beauty-Wellness & Rituals",
    fieldType: "text",
    childs: Colours
  },

  // Home-Kitchen
  {
    option: 'Type',
    label: 'Type',
    parent: "Home-Kitchen",
    childs: [
      { option: 'Knives & Utensils', label: 'Knives & Utensils' },
      { option: 'Boards', label: 'Boards' },
      { option: 'Other', label: 'Other' },
      // { option: 'Cook', label: 'Cook' },
      // { option: 'Drink', label: 'Drink' },
      // { option: 'Eat', label: 'Eat' },
      // { option: 'Serve', label: 'Serve' },
      // { option: 'Decorate', label: 'Decorate' },
    ]
  },

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Kitchen",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Kitchen",
    fieldType: "text",
    childs: []
  },

  // Home-Ceramics
  {
    option: 'Type',
    label: 'Type',
    parent: "Home-Ceramics",
    childs: [
      { option: "Cups & Mugs", label: 'Cups & Mugs' },
      { option: 'Plates & Bowls', label: 'Plates & Bowls' },
      { option: 'Platters', label: 'Platters' },
      { option: 'Jugs', label: 'Jugs' },
      { option: 'Kitchen Accessories', label: 'Kitchen Accessories' },
      { option: 'Vases', label: 'Vases' },
      { option: 'Candle holder', label: 'Candle holder' },
      { option: 'Other', label: 'Other' },
    ]
  },

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Ceramics",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Ceramics",
    fieldType: "text",
    childs: []
  },



  // // Home-Pantry
  // {
  //   option: 'Type',
  //   label: 'Type',
  //   parent: "Home-Pantry",
  //   childs: [
  //     { option: 'Food', label: 'Food' },
  //     { option: 'Beverage', label: 'Beverage' },
  //   ]
  // },

  // Home-Bed
  {
    option: 'Type',
    label: 'Type',
    parent: "Home-Bed",
    childs: [
      { option: 'Sheets', label: 'Sheets' },
      { option: 'Pillows & Cushions', label: 'Pillows & Cushions' },
      { option: 'Duvets', label: 'Duvets' },
      { option: 'Quilts & Blankets', label: 'Quilts & Blankets' },
      { option: 'Other', label: 'Other' },
    ]
  },

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Bed",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Bed",
    fieldType: "text",
    childs: []
  },



  // Home-Outdoor & Garden
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Outdoor & Garden",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Outdoor & Garden",
    fieldType: "text",
    childs: []
  },

  // Home-Candles

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Candles",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Candles",
    fieldType: "text",
    childs: []
  },

  // Home-Decor

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Decor",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Decor",
    fieldType: "text",
    childs: []
  },


  // Home-Cleaning
  {
    option: 'Type',
    label: 'Type',
    parent: "Home-Cleaning",
    childs: [
      { option: 'Cloths & Tea Towels', label: 'Cloths & Tea Towels' },
      { option: 'Cleaning Products', label: 'Cleaning Products' },
      { option: 'Other', label: 'Other' },
    ]
  },
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Cleaning",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Cleaning",
    fieldType: "text",
    childs: []
  },

  // Home-Pets

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Home-Pets",
    childs: Colours
  },
  {
    option: 'Size',
    label: 'Size',
    parent: "Home-Pets",
    fieldType: "text",
    childs: []
  },




  // Art-Prints
  {
    option: 'Size',
    label: 'Size',
    parent: "Art-Prints",
    fieldType: "text",
    childs: [
      // { option: 'A 6', label: 'A 6' },
      // { option: 'A 5', label: 'A 5' },
      // { option: 'A 4', label: 'A 4' },
      // { option: 'A 3', label: 'A 3' },
      // { option: 'A 2', label: 'A 2' },
      // { option: 'A 1', label: 'A 1' },
    ]
  },

  {
    option: 'Colour',
    label: 'Colour',
    parent: "Art-Prints",
    childs: Colours
  },
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Art-Prints",
    fieldType: "text",
    childs: []
  },

  // Art-Fine Art Photography
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Art-Fine Art Photography",
    childs: Colours
  },
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Art-Fine Art Photography",
    fieldType: "text",
    childs: []
  },
  // Art-Originals
  // Art-Sculpture & Wall Hanging
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Art-Originals",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Art-Sculpture & Wall Hanging",
    fieldType: "text",
    childs: []
  },

  // Pantry
  {
    option: 'Size',
    label: 'Size',
    parent: "Pantry",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Flavours',
    label: 'Flavours',
    parent: "Pantry",
    fieldType: "text",
    childs: []
  },

  // Furniture
  {
    option: 'Size',
    label: 'Size',
    parent: "Furniture",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Furniture",
    fieldType: "text",
    childs: []
  },
  // Stationery & Games-Cards
  {
    option: 'Type',
    label: 'Type',
    parent: "Stationery & Games-Cards",
    // fieldType: "text",
    childs: [
      { option: 'Christmas', label: 'Christmas' },
      { option: 'Wedding', label: 'Wedding' },
      { option: 'Birthday', label: 'Birthday' },
      { option: 'New Baby', label: 'New Baby' },
      { option: 'Kids', label: 'Kids' },
      { option: 'Sympathy', label: 'Sympathy' },
      { option: 'Thank you', label: 'Thank you' },
      { option: 'For Him | For Her', label: 'For Him | For Her' },
      { option: 'Blank Cards', label: 'Blank Cards' },
      { option: 'Other', label: 'Other' },
    ]
  },

  {
    option: 'Size',
    label: 'Size',
    parent: "Stationery & Games-Cards",
    fieldType: "text",
    childs: []
  },
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Stationery & Games-Cards",
    fieldType: "text",
    childs: []
  },
  // Stationery & Games-Wrap
  {
    option: 'Oversized Delivery Fee',
    label: 'Oversized Delivery Fee',
    parent: "Stationery & Games-Wrap",
    fieldType: "text",
    childs: []
  },


  // Stationery & Games-Planners & Calenders
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Stationery & Games-Planners & Calenders",
    childs: Colours
  },
  
  // Stationery & Games-Journals & Notebooks
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Stationery & Games-Journals & Notebooks",
    childs: Colours
  },

  // Stationery & Games-Other
  {
    option: 'Colour',
    label: 'Colour',
    parent: "Stationery & Games-Other",
    childs: Colours
  },
]

export const subCategoryChildOptions = ["Type", "Size", "Colour", "Oversized Delivery Fee", "Scent", "Metal", "Flavours"];

export const freeShipping = [
  { option: "Free Shipping on orders over $50", label: "Free Shipping on orders over $50", price: 5000 },
  { option: "Free Shipping on orders over $75", label: "Free Shipping on orders over $75", price: 7500 },
  { option: "Free Shipping on orders over $100", label: "Free Shipping on orders over $100", price: 10000 },
  { option: "Free Shipping on orders over $125", label: "Free Shipping on orders over $125", price: 12500 },
  { option: "Free Shipping on orders over $150", label: "Free Shipping on orders over $150", price: 15000 },
  { option: "Free Shipping on orders over $200", label: "Free Shipping on orders over $200", price: 20000 },
  { option: "Free Shipping on orders over $250", label: "Free Shipping on orders over $250", price: 25000 },
]

export const size = [
  { option: '0-3months', label: '0-3months' },
  { option: '4-8months', label: '4-8months' },
  { option: '9-15months', label: '9-15months' },
]

export const color = [
  { option: 'red', label: 'Red' },
  { option: 'green', label: 'Green' },
  { option: 'blue', label: 'Blue' },
  { option: 'orange', label: 'Orange' },
  { option: 'pink', label: 'Pink' },
]

export const listingFields = [
  {
    key: 'variantColor',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: subCategoryChild.filter((e) => e.option === 'Colour').flatMap(e => e.childs).filter((e)=>e.option != "Custom"),
    filterConfig: {
      indexForSearch: true,
      label: 'Colour',
      searchMode: 'has_any',
      group: 'primary',
    },
    showConfig: {
      label: 'Colour',
    },
    saveConfig: {
      label: 'Colour',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },
  {
    key: 'primaryCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: primaryCategory,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Collections',
      group: 'primary',
      searchMode: 'has_all',
    },
    showConfig: {
      label: 'Collections',
      isDetail: true,
    },
    saveConfig: {
      label: 'Collections',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a Collections.',
    },
  },
  {
    key: 'subCategory',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: subCategory,
    filterConfig: {
      indexForSearch: true,
      label: 'Sub Collection',
      group: 'primary',
    },
    showConfig: {
      label: 'Sub Collection',
      isDetail: true,
    },
    saveConfig: {
      label: 'Sub Collection',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a Sub Category.',
    },
  },
  {
    key: 'variantSize',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: subCategoryChild.filter((e) => e.option === 'Size').flatMap(e => e.childs),
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Size',
      group: 'primary',
      searchMode: 'has_any',
    },
    showConfig: {
      label: 'Size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Size',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a Size.',
    },
  },
  {
    key: 'type',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: subCategoryChild.filter((e) => e.option === 'Type').flatMap(e => e.childs),
    filterConfig: {
      indexForSearch: true,
      label: 'Type',
      searchMode: 'has_all',
      group: 'primary',
    },
    showConfig: {
      label: 'Type',
    },
    saveConfig: {
      label: 'Type',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;


export const RETURN_POLICY_PDF_URL="https://res.cloudinary.com/rita-edited/image/upload/v1722927548/live/Return_policy_tips_1_ahzluy.pdf";  


const VARIANT_DEFAULT = "variants.default" // 	scale	750px	750px
const VARIANT_LANDSCAPE_CROP = "variants.landscape-crop" // crop	400px	267px
const VARIANT_LANDSCAPE_CROP_2X = "variants.landscape-crop2x" // 	crop	800px	533px
const VARIANT_LANDSCAPE_CROP_4X = "variants.landscape-crop4x"// landscape-crop4x	crop	1600px	1066px
const VARIANT_LANDSCAPE_CROP_6X = "variants.landscape-crop6x"// landscape-crop6x	crop	2400px	1602px
const VARIANT_SCALED_SMALL = "variants.scaled-small"// 	scale	320px	320px
const VARIANT_SCALED_MEDIUM = "variants.scaled-medium"// scale	750px	750px
const VARIANT_SCALED_LARGE = "variants.scaled-large" // 	scale	1024px	1024px
const VARIANT_SCALED_XLARGE = "variants.scaled-xlarge" // 	scale	2400px	2400px
const VARIANT_SQUARE_SMALL = "square-small" // 	crop	240px	240px
const VARIANT_SQUARE_SMALL2X = "square-small2x"; // 	crop	480px	480px
const VARIANT_FACEBOOK = "facebook"; // crop	1200px	630px
const VARIANT_TWITTER = "twitter"; // crop	600px	314px

export const allImageVariantTypes = [
  VARIANT_DEFAULT,
  VARIANT_LANDSCAPE_CROP,
  VARIANT_LANDSCAPE_CROP_2X,
  VARIANT_LANDSCAPE_CROP_4X,
  VARIANT_LANDSCAPE_CROP_6X,
  VARIANT_SCALED_MEDIUM,
  VARIANT_SCALED_LARGE,
  VARIANT_SCALED_XLARGE,
  VARIANT_SCALED_SMALL,
  VARIANT_SQUARE_SMALL,
  VARIANT_SQUARE_SMALL2X
]


const publicDataFieldsSchema = {

  "listingType": "string >>>> default-purchase",
  "transactionProcessAlias": "string  >>>> default-purchase/release-1",
  "unitType": "string >>>> item",

  // EditListingDetailsForm
  title: "string",
  description: "string",
  primaryCategory: "string  && filter-schema-set",
  subCategory: "string  && filter-schema-set",
  type: "string  && filter-schema-set",
  collectionType: allCollectionDropdowns, //"collectionType && filter-schema-set",  
  variants: [
    {
      price: "number",
      overSizedDeliveryFee: "number",
      quantity: "number",
      size: "string",
      customSize: "string",
      metal: "string",
      color: "string",
      customColor: "string",
      scent: "string",
      flavours: "string"
    },
  ],
  variantSize: "array  && filter-schema-set",
  variantColor: "array  && filter-schema-set",
  variantMetal: "array  && filter-schema-set",
  variantFlavours: "array  && filter-schema-set",
  variantScent: "array  && filter-schema-set",

  totalStockAvailable: "number && filter-schema-set && used on searchPage to show available stock",
  stockDefault: "number && to store quantity if collectionType = 'This is a single product'",
  listingEnabled: "string && filter-schema-set && used to delete a listing as we can not delete a particular listing", //status == listingEnabled,deleted

  businessCollection: "bool", // from user Profile to add in listing for search purpose pakki more check in list (true,false)
  enableAfterpay: "string", // from user Profile to add in listing for search purpose (Enabled,Disabled) 
  pickupStore: "string", // from user Profile to add in listing for search purpose (Yes , No)
  pickupStoreShipped: "string", // from user Profile to add in listing for search purpose (1 Days) upto 8 days 
  address: "string", // from user Profile to add in listing or search purpose
  city: "string", // from user Profile to add in listing or search purpose
  state: "string", // from user Profile to add in listing or search purpose
  postCode: "string", // from user Profile to add in listing or search purpose

  // EditListingPricingAndStockForm
  additionalDeliveryFee: "number",
  customisation: [
    { customisationTitle: "text", customisationPrice: "number" }
  ],

  // EditListingPhotosForm
  heroImageId: "string",
  images: "array of uuids"

}

// on update of current user we have to update the user all listing fields are ["businessCollection","enableAfterpay","pickupStore","pickupStoreShipped","address","city","state","postCode"]
const currentUserPublicDataFieldsSchema = {
  // Details
  "storeName": "string",
  "storeLocation": "object",
  // {}
  "welcomeMessage": "string",
  "pickupStore": "string",
  "pickupStoreShipped": "string",
  "returnPolicy": "string",

  "returnPolicyImageUrl": "object",
  // { secure_url: res.secure_url, original_filename: res.original_filename }

  "faq": "string",
  "loveNote": "string",

  "loveNoteImageUrl": "object",
  // { secure_url: res.secure_url, original_filename: res.original_filename }

  "businessCollection": "bool", // for Pakki More check 


  // currentUserApllySellPublicDataFieldsSchema
  "businessName": "string",
  "socialLink": "string",
  "websiteLink": "string",
  "description": "string",
  "productCount": "string",
  "currentProductDescription": "string",
  "yourLocation": "obj",
  "anyDescription": "string",
}

// protectedData
const currentUserProtectedDataFieldsSchema = {
  "Seller": "bool",
  "Membership": "bool"
}

const discounts = [{
  durationStartDate: "", // moment().unix() start time in unix
  durationEndDate: "", // moment().unix()  end time in unix
  discountCode: "string", // random text by user 
  percent: "string" // from 5 to 100 %
}];

// privateData
const currentUserPrivateDataFieldsSchema = {
  discounts: discounts,
  cartUpdateAt: "number", //JIRA-165 moment().unix() set search schema float 
  mailSendForPerWeek: "string", //JIRA-165 (pending done)  set search schema enum
  lastCartItemMailTime: "number"//JIRA-165 moment().unix() set search schema float 
};

const cloudinaryResponseSchema = {
  "asset_id": "791d81205c34cf0f68c3e474ea5707e9",
  "public_id": "dev/ceg_vihljw",
  "version": 1711623801,
  "version_id": "83a25cd6c95eb1f25332bad51f82ea63",
  "signature": "c263f675ae92ae46ff4df9e287baaf1b41bf1fb6",
  "width": 1920,
  "height": 1080,
  "format": "pdf",
  "resource_type": "image",
  "created_at": "2024-03-28T11:03:21Z",
  "tags": [],
  "pages": 2,
  "bytes": 169786,
  "type": "upload",
  "etag": "e272f0c3d3ebcb1c46e0b1e41613d802",
  "placeholder": false,
  "url": "http://res.cloudinary.com/rita-edited/image/upload/v1711623801/dev/ceg_vihljw.pdf",
  "secure_url": "https://res.cloudinary.com/rita-edited/image/upload/v1711623801/dev/ceg_vihljw.pdf",
  "folder": "dev",
  "access_mode": "public",
  "existing": false,
  "original_filename": "ceg"
}


const cartItemSchema = {
  "title": "number",
  "images": "string",
  "listingId": "string",
  "authorId": "string",

  "color": "string",
  "customColor": "string",

  "size": "string",
  "customSize": "string",

  "scent": "string",
  "flavours": "string",
  "metal": "string",

  "quantity": "number",
  "price": "number",
  "currency": "NZD",

  "shippingOption": "number", // only single price of a particular author 
  "shippingLabel":"string",

  "overSizedDeliveryFee": "", // multiple it with quantity in line Item

  "additionalDeliveryFee": "number", // multiple it with quantity in line Item

  "customisation": "number", // multiple it with quantity in line Item
  "customisationTitle": "string",

  discountCode:"string", 
  discount:"number"

  // "currentStock": 4 during fetch add 
  // aQuery: aQuery || "", // for algolia events
  // aQueryId: aQueryId || "" // for algolia events

};

const typesenceSchema = {
  "name": "dev",
  "fields": [
    {
      "name": "title",
      "type": "string",
      "facet": true
    },
    {
      "name": "description",
      "type": "string",
      "facet": true
    },
    {
      "name": "listingType",
      "type": "string"
    },
    {
      "name": "transactionProcessAlias",
      "type": "string"
    },
    {
      "name": "unitType",
      "type": "string"
    },
    {
      "name": "subCategory",
      "type": "string",
      "facet": true
    },
    {
      "name": "primaryCategory",
      "type": "string",
      "facet": true
    },
    {
      "name": "user",
      "type": "object",
      "facet": true
    },
    {
      "name": "variants",
      "type": "auto",
      "index": false,
      "optional": true
    },
    {
      "name": "variantColor",
      "type": "string[]",
      "facet": true
    },
    {
      "name": "variantSize",
      "type": "string[]",
      "facet": true
    },
    {
      "name": "customisation",
      "type": "auto",
      "index": false,
      "optional": true
    },
    {
      "name": "heroImageId",
      "type": "string",
      "facet": true
    },
    {
      "name": "imageURL",
      "type": "string[]",
      "facet": true
    },
    {
      "name": "shippingDiscount",
      "type": "auto",
      "index": false,
      "optional": true
    },
    {
      "name": "price",
      "type": "int32",
      "facet": true
    },
    {
      "name": "priceCurrency",
      "type": "string",
      "facet": true
    },
    {
      "name": "ruralShipping",
      "type": "int32",
      "facet": true
    },
    {
      "name": "shippingPriceInSubunitsOneItem",
      "type": "int32",
      "facet": true
    },
    {
      "name": "stockDefault",
      "type": "int32"
    },
    {
      "name": "pickupEnabled",
      "type": "bool",
      "facet": true
    },
    {
      "name": "shippingEnabled",
      "type": "bool",
      "facet": true
    },
    {
      "name": "additionalDeliveryFee",
      "type": "int32"
    },
    {
      "name": "type",
      "type": "string"
    }
  ],
  "default_sorting_field": "price",
  "enable_nested_fields": true
};


const schemaTysensce = {
  "title": "String Value",
  "description": "String Value",
  "listingType": "String Value",
  "transactionProcessAlias": "String Value",
  "unitType": "String Value",
  "subCategory": "String Value",
  "primaryCategory": "String Value",
  "variants": {
    "key": "value"
  },
  "variantColor": {
    "key": "value"
  },
  "variantSize": {
    "key": "value"
  },
  "customisation": {
    "key": "value"
  },
  "heroImageId": "String Value",
  "allImages": "String Value",
  "shippingDiscount": {
    "key": "value"
  },
  "price": 0,
  "priceCurrency": "String Value",
  "ruralShipping": 0,
  "shippingPriceInSubunitsOneItem": 0,
  "pickupEnabled": true,
  "shippingEnabled": true,
  listingPurchased:false // checked any transaction is confirmed or not 
};
