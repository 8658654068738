import React from 'react';

import css from "./FieldToggleBar.module.css"

const FieldToggleBar = (props) => {
    
    return (
        <label className={css.switch}>
            <input type="checkbox" {...props} />
            <span className={css.sliderRound}></span>
        </label>
    )
}

export default FieldToggleBar