import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';

export const validSearchCat = [
  { value: "Pēpi", option: "Pēpi", label: "pepi-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory", searchKey:"collection" },
  { value: "Kids", option: "Kids", label: "kids-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Wear", option: "Wear", label: "wear-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Jewellery", option: "Jewellery", label: "jewellery-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Lifestyle & Beauty", option: "Lifestyle & Beauty", label: "lifestyle-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory", searchKey:"collection" },
  { value: "Home", option: "Home", label: "home-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Art", option: "Art", label: "art-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Pantry", option: "Pantry", label: "pantry-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: true, option: "Pakihi Māori", label: "pakhimaori-landing-page", key: "pub_businessCollection", publicDataKey: "publicData.businessCollection",searchKey:"businessCollection" },
  { value: "Stationery & Games", option: "Stationery & Games", label: "stationary-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: "Furniture", option: "Furniture", label: "furniture-landing-page", key: "pub_primaryCategory", publicDataKey: "publicData.primaryCategory",searchKey:"collection" },
  { value: true, option: "The Edit", label: "edit-landing-page", key: "pub_theEdit", publicDataKey: "publicData.theEdit",searchKey:"theEdit" }
];

// pub_businessCollection

export const getSearchCategoryParams = (params) => {
  const { pageId, subCat } = params;
  const searchQuerys = validSearchCat.map(st => st.label);
  if (searchQuerys.includes(pageId)) {
    const variantPrefix = 'listing-card';
    const selectedCatValues = validSearchCat.find((st) => st.label == pageId);
    const { key, value } = selectedCatValues || {};
    const primaryCategoryMaybe = { [key]: value };
    const subCategoryMaybe = subCat ? { pub_subCategory: subCat } : {};

    // cat have stock >>>  pub_totalStockAvailable:"0,",
    return {
      selectedCatValues,
      page: 1,
      perPage: 8,
      ...primaryCategoryMaybe,
      ...subCategoryMaybe,
      pub_totalStockAvailable: '1,',
      include: ["author", 'images'],
      'fields.listing': [
        'title',
        'price',
        'publicData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', "profile.publicData.storeName"],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, 1),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, 1),
      // 'limit.images': 1,
    };

  } else {
    return false;
  }
};

export const loadData = (params, search) => dispatch => {
  const { pageId } = params;
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  const searchQuery = getSearchCategoryParams(params);
  return dispatch(fetchPageAssets(pageAsset, hasFallbackContent, searchQuery));
};
