// template_id, data
// const { email, userName = "", ...rest } = data;

export const SELLER_NEW_APPLICATION_TEMPLATE_ID = "d-c58696c4700d4f3787e27023e95e0763";
export const ADMIN_NEW_APPLICATION_TEMPLATE_ID = "d-4a705f6804e64b55875b8305cd2207ea";
export const APPROVED_SELLER_APPLICATION_TEMPLATE_ID = "d-c48e24a6caa54358991a14a07663c358";
export const DENIED_SELLER_APPLICATION_TEMPLATE_ID ='d-f35034ff2ac841b1b89192741a3b527c';
export const QUANTITY_DROPS_TEMPLATE_ID ='d-7f3094361be349f09b976ce4cd72f47b';
export const ABANDONED_TEMPLATE_ID = 'd-45d62d84fde8450c8c9095f2f49e52a9';
export const DISPUTE_CUSTOMER_RAISED_TEMPLATE_ID ="d-2d8c6fb2533748ad9f0a45c301b0ef27";
