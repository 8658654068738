import React from 'react';
import classNames from 'classnames';

import IconProfileCard from '../IconProfileCard/IconProfileCard';

import css from './ToolTip.module.css';

const ToolTip = props => {
  const { className, tipText, headingText = "", subhedingText = "", bulletPoints, subhedingTextArray } = props;
  const classes = classNames(className || css.containerWrapper);

  return (
    <span className={classes}>
      <label>
        {tipText}
      </label>
      <span className={css.tooltipContainer}>
        <IconProfileCard type="info" />
        <div className={css.tooltip}>
          <div className={css.tooltipFlex}>
            <div>
              <IconProfileCard type="info-2" />
            </div>
            <span className={css.tooltipTtext}>
              <div className={css.headingText}>{headingText}</div>
              {subhedingText ? <div className={css.subhedingText}>{subhedingText}</div> : null}
              {subhedingTextArray && Array.isArray(subhedingTextArray) ? subhedingTextArray.map((st, i) => <div className={css.subhedingText}>{st}</div>) : null}
              {bulletPoints && Array.isArray(bulletPoints) ? bulletPoints.map((st, i) => <div className={css.subhedingText} key={st + i} >{st}</div>) : null}
            </span>
          </div>
        </div>
      </span>
    </span>
  );
};

export default ToolTip;
