import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { onAdminNewApplicationMail, onSellerNewApplicationMail } from '../../util/api';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/AppplySellPage/CLEAR_UPDATED_FORM';

export const UPDATE_PROFILE_REQUEST = 'app/AppplySellPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/AppplySellPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/AppplySellPage/UPDATE_PROFILE_ERROR';

export const APPLICATION_MAILS_REQUEST = 'app/AppplySellPage/APPLICATION_MAILS_REQUEST';
export const APPLICATION_MAILS_SUCCESS = 'app/AppplySellPage/APPLICATION_MAILS_SUCCESS';
export const APPLICATION_MAILS_FAILURE = 'app/AppplySellPage/APPLICATION_MAILS_FAILURE';

// ================ Reducer ================ //

const initialState = {
  updateInProgress: false,
  updateProfileError: null,
  sendMailsInprogress: false,
  sendMailsError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };
    case APPLICATION_MAILS_REQUEST:
      return {
        ...state,
        sendMailsInprogress: true,
        sendMailsError: null,
      };
    case APPLICATION_MAILS_SUCCESS:
      return {
        ...state,
        sendMailsInprogress: false,
      };
    case APPLICATION_MAILS_FAILURE:
      return {
        ...state,
        sendMailsInprogress: false,
        sendMailsError: action.payload,
      };
    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});



// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});


// Sending application mails to admin and seller
export const applicationMailsRequest = () => ({
  type: APPLICATION_MAILS_REQUEST,
});

export const applicationMailsSuccess = () => ({
  type: APPLICATION_MAILS_SUCCESS,
});

export const applicationMailsFailure = (error) => ({
  type: APPLICATION_MAILS_FAILURE,
  payload: error,
});
// ================ Thunk ================ //

export const applicationMails = (mailParams, email) => {
  return (dispatch, getState, sdk) => {
    dispatch(applicationMailsRequest());

    return Promise.all([
      onAdminNewApplicationMail({
        ...mailParams,
        email: process.env.REACT_APP_ADMIN_SELLER_APPLY_MAIL,
      }),
      onSellerNewApplicationMail({ ...mailParams, email }),
    ])
    .then(() => {
      dispatch(applicationMailsSuccess());
    })
    .catch(error => {
      console.error('Error in applicationMails:', error);
      dispatch(applicationMailsFailure(error));
    });
  };
};

export const updateProfile = (actionPayload, mailParams, email) => {
  return async (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    try {
      const response = await sdk.currentUser.updateProfile(actionPayload, queryParams);
      dispatch(updateProfileSuccess(response));

      // Sending application mails
      await dispatch(applicationMails(mailParams, email));

      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    } catch (error) {
      dispatch(updateProfileFailure(storableError(error)));
      throw error; // Ensure error is thrown so that the calling function can handle it if needed
    }
  };
};