import aa from 'search-insights';

//A user clicked on a search result. Send a click event to capture the search query, which item was clicked, and the position in the search results.
export const PRODUCT_CLICKED = "PLP: Product Clicked";
export const CLICKED_OBJECT_IDS_AFTER_SEARCH = "clickedObjectIDsAfterSearch";

// A user added an item to the cart on the product-detail page after performing a search.
export const PRODUCT_ADDED_CART_AFTER_SEARCH = "PDP: Product Added to Cart after Search";
export const CONVERTED_OBJECT_IDS_AFTER_SEARCH = "convertedObjectIDsAfterSearch";

// A user added an item to the cart. With or without a proceeding search.
export const PRODUCT_ADDED_CART = "PDP: Product Added to Cart";
export const CONVERTED_OBJECT_IDS = "convertedObjectIDs";

// A user clicked on a search result in the autocomplete menu.
export const AUTOCOMPLETE_PRODUCT_CLICKED = "Autocomplete: Product Clicked";
export const AUTOCOMPLETE_CLICKED_OBJECT_IDS_AFTER_SEARCH = "clickedObjectIDsAfterSearch";

// A user selected a facet value.
export const FILTER_CLICKED = "PLP: Filter Clicked";
export const CLICKED_FILTERS = "clickedFilters";

//  A user viewed a landing page.
export const FILTER_VIEWED = "PLP: Filter Viewed";
export const VIEWED_FILTERS = "viewedFilters";

// A user viewed a product page from an email, newsletter, push notification, or any other page.
export const PRODUCT_VIEWED = "Product Viewed";
export const PRODUCT_VIEWED_FILTERS = "viewedObjectIDs";


// A user added an item to the cart from the product-listing page after performing a search using autocomplete.
export const PRODUCT_ADD_CART_FROM_AUTOCOMPLETE_VIEWED = "PLP: Product Added to Cart from Autocomplete";
export const ADDED_TO_CART_AFTER_SEARCH = "addedToCartObjectIDsAfterSearch";

// A user purchased an item on the product-detail page after performing a search.
export const PRODUCT_PURCHASED_AFTER_SEARCH = "PDP: Product Purchased after Search";
export const PURCHASED_AFTER_SEARCH = "purchasedObjectIDsAfterSearch";

// A user added an item to the cart without a preceding search.
export const PRODUCT_ADDED_CART_WITHOUT_SEARCH = "PDP: Product Added to Cart";
export const ADDED_CART_OBJECT_ID = "addedToCartObjectIDs";

// A user purchased an item without a preceding search.
export const PRODUCT_PURCHASED_WITHOUT_SEARCH = "PDP: Product Purchased";
export const PURCHASED_OBJECT_ID = "purchasedObjectIDs";

// A user selected a facet value and converted.
export const FILTER_CONVERSATION = "Filter Conversion";
export const CONVERTED_FILTERS = "convertedFilters";



// sendEvent('click', i, PRODUCT_CLICKED_EVENT, {
//     objectIds: [i.objectID],
//     ...userTokenMaybe,
//   })

export const algoliaEventTrackFn = async (method, data) => {
    try {
        const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
        const REACT_APP_ALGOLIA_SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;

        // load script for algolia events
        await aa('init', {
            appId: ALGOLIA_APP_ID,
            apiKey: REACT_APP_ALGOLIA_SEARCH_API_KEY,
        });
        
        return aa(method, data)
        .then((response)=> console.log(response, '&&& &&& => response'))
        .catch(e=> console.log(e, '&&& &&& => e')
        )
    } catch (e) {
        console.log(e, '&&& algoliaEventTrackFn &&& => e');
    }
};
