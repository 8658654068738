import React from 'react';
import { useHistory } from 'react-router-dom';

import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import {  IconProfileCard, ResponsiveImage } from '..';

// import NoImageIcon from '../ResponsiveImage/NoImageIcon';
import { LISTING_CARD_2X } from '../../util/types';
import { AUTOCOMPLETE_CLICKED_OBJECT_IDS_AFTER_SEARCH, AUTOCOMPLETE_PRODUCT_CLICKED, CLICKED_OBJECT_IDS_AFTER_SEARCH, PRODUCT_CLICKED, algoliaEventTrackFn } from '../../containers/AlgoliaSearchPage/AlgoliaEvents';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { onGetEventAlgoliaData } from '../../util/data';

import css from './AlogoliaSearchCard.module.css';

const AlogoliaSearchCard = props => {

  const history = useHistory();
  const routes = useRouteConfiguration();

  const { hit, currentUser, algoliaState, currentPage } = props;

  const { title = "T", objectID, lisingImages, price, __queryID, __position, user } = hit || {}; 
  const { storeName } = user?.publicData || {}
  const lisingImageUrl = Array.isArray(lisingImages) && lisingImages.length ? lisingImages[0] : false;
  const variants = [LISTING_CARD_2X];
  const image = lisingImageUrl ? {
    type: "image",
    attributes: {
      variants: {
        "listing-card-2x": {
          width: 800,
          height: 533,
          url: lisingImageUrl
        },
        "scaled-medium":{
          width: 750,
          height: 750,
          url: lisingImageUrl
        }
      }
    }
  } : lisingImageUrl;


  return (
    <div className={css.root} key={objectID} onClick={(e) => {
      e.preventDefault();
      if (currentPage != "AlgoliaSearchPage") {
        history.push(createResourceLocatorString('ListingPage', routes, { id: objectID, slug: createSlug(title) }, {}));
        return;
      } else {
        const index = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;
        const autocompleteFilter = (algoliaState && typeof algoliaState == "object") && algoliaState.query;
        // event tracking for algolia
        try {
          if (currentUser?.id?.uuid) {
            const getAlgoliaData = onGetEventAlgoliaData(currentUser, hit, algoliaState);
            const eventName = autocompleteFilter ? AUTOCOMPLETE_PRODUCT_CLICKED : PRODUCT_CLICKED;
            const method = autocompleteFilter ? AUTOCOMPLETE_CLICKED_OBJECT_IDS_AFTER_SEARCH : CLICKED_OBJECT_IDS_AFTER_SEARCH;
            algoliaEventTrackFn(method, {
              index,
              eventName,
              queryId: __queryID,
              positions: [__position],
              ...getAlgoliaData
            })
          };
        } catch (e) {
          console.log(e, '&&& &&& => e');
        }

        const searchParams = {
          aQueryId: __queryID,
          aPosition: __position,
          ...(autocompleteFilter ? { aQuery: autocompleteFilter } : {})
        };
        console.log(searchParams, '&&& &&& => searchParams');
        return history.push(createResourceLocatorString('ListingPage', routes, { id: objectID, slug: createSlug(title) }, searchParams));
      }
    }} >
      {/* <NamedLink
        name="ListingPage"
        params={{ id: objectID, slug: createSlug(title) }}
        className={css.link}
        isSearchPage={true}
      > */}
        <div className={css.container}>
          {
            lisingImageUrl ?
              <ResponsiveImage
                alt={"displayName"}
                image={image}
                variants={variants}
              />
              :
              <div className={css.noImageIcon}><IconProfileCard type="no_image" /><FormattedMessage id="ResponsiveImage.noImage" /></div>
          }
          <p className={css.price}>{price ? `$${price.toFixed(2)}` : null}</p>
          <p className={css.title}>{title}</p>
          <p className={css.storeName}>{storeName}</p>
        </div>
      {/* </NamedLink> */}
    </div>
  );
};

export default AlogoliaSearchCard;
