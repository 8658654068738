import { fetchCurrentUser } from '../../ducks/user.duck';
import { onNapStoreListingsApi, onQueryListings } from '../../util/api';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { DELETE_ALGOLIA_TYPE, LISTING_STATE_CLOSED, LISTING_STATE_PUBLISHED, PUB_listingEnabled, PUB_listingEnabled_DELETED, UPDATE_ALGOLIA_TYPE } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { algoliaCreateUpdateDeleteData } from '../EditListingPage/EditListingPage.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 100;

const { UUID, Money } = sdkTypes;

const REACT_APP_ALGOLIA_LISTING_INDEX = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';


export const ON_DELETE_LISTING_REQUEST = 'app/ManageListingsPage/ON_DELETE_LISTING_REQUEST';
export const ON_DELETE_LISTING_SUCCESS = 'app/ManageListingsPage/ON_DELETE_LISTING_SUCCESS';
export const ON_DELETE_LISTING_ERROR = 'app/ManageListingsPage/ON_DELETE_LISTING_ERROR';

export const ON_DUPLICATE_LISTING_REQUEST = 'app/ManageListingsPage/ON_DUPLICATE_LISTING_REQUEST';
export const ON_DUPLICATE_LISTING_SUCCESS = 'app/ManageListingsPage/ON_DUPLICATE_LISTING_SUCCESS';
export const ON_DUPLICATE_LISTING_ERROR = 'app/ManageListingsPage/ON_DUPLICATE_LISTING_ERROR';

export const NAP_STORE_LISTING_REQUEST = 'app/ManageListingsPage/NAP_STORE_LISTING_REQUEST';
export const NAP_STORE_LISTING_SUCCESS = 'app/ManageListingsPage/NAP_STORE_LISTING_SUCCESS';
export const NAP_STORE_LISTING_ERROR = 'app/ManageListingsPage/NAP_STORE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  deleteListing: null,
  deleteListingError: null,
  duplicateListing: null,
  duplicateListingError: null,
  liveListingsLength: 0,
  draftListingsLength: 0,
  closedListingsLength: 0,
  napStoreListingRequest: false,
  napStoreListingError: false,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload.data,
        pagination: payload.pagination,
        queryInProgress: false,
        liveListingsLength: payload.liveListingsLength,
        draftListingsLength: payload.draftListingsLength,
        closedListingsLength: payload.closedListingsLength
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload,
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload,
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ON_DELETE_LISTING_REQUEST:
      return {
        ...state,
        deleteListing: payload,
        deleteListingError: null,
      };
    case ON_DELETE_LISTING_SUCCESS:
      return {
        ...state,
        deleteListing: null,
        deleteListingError: null
      };
    case ON_DELETE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        deleteListing: null,
        deleteListingError: {
          listingId: state.deleteListing,
          error: payload,
        },
      };
    }

    case ON_DUPLICATE_LISTING_REQUEST:
      return {
        ...state,
        duplicateListing: payload,
        duplicateListingError: null,
      };
    case ON_DUPLICATE_LISTING_SUCCESS:
      return {
        ...state,
        duplicateListing: null,
        duplicateListingError: null
      };
    case ON_DUPLICATE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        duplicateListing: null,
        duplicateListingError: {
          listingId: state.duplicateListing,
          error: payload,
        },
      };
    }

    case NAP_STORE_LISTING_REQUEST:
      return {
        ...state,
        napStoreListingRequest: true,
        napStoreListingError: false,
      };
    case NAP_STORE_LISTING_SUCCESS:
      return {
        ...state,
        napStoreListingRequest: false,
        napStoreListingError: false,
      };
    case NAP_STORE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        napStoreListingRequest: false,
        napStoreListingError: payload,
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: listingId,
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: listingId,
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const onDeleteListingRequest = listingId => ({
  type: ON_DELETE_LISTING_REQUEST,
  payload: listingId,
});

export const onDeleteListingSucess = response => ({
  type: ON_DELETE_LISTING_SUCCESS,
  payload: response,
});

export const onDeleteListingError = e => ({
  type: ON_DELETE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const onDuplicateListingRequest = listingId => ({
  type: ON_DUPLICATE_LISTING_REQUEST,
  payload: listingId,
});

export const onDuplicateListingSucess = response => ({
  type: ON_DUPLICATE_LISTING_SUCCESS,
  payload: response,
});

export const onDuplicateListingError = e => ({
  type: ON_DUPLICATE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const napStoreListingRequest = () => ({
  type: NAP_STORE_LISTING_REQUEST,
});

export const napStoreListingSucess = response => ({
  type: NAP_STORE_LISTING_SUCCESS,
  payload: response,
});

export const napStoreListingError = e => ({
  type: NAP_STORE_LISTING_ERROR,
  error: true,
  payload: e,
});


const getImageParams = (search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_listingEnabled: PUB_listingEnabled,
    include: ['images', 'currentStock', 'publicData'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`,"variants.scaled-medium"],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }
}

export const getProperResponseOfListing = (response, config) => {
  const responseEntries = response.data.data.length ? updatedEntities({}, response.data, { listingFields: config?.listing?.listingFields }) : {};
  const resources = response.data.data.length ? response.data.data.map((st) => ({ id: st.id, type: "listing" })) : [];
  const responseArray = response.data.data.length ? denormalisedEntities(responseEntries, resources, false) : [];
  return responseArray;
};

export const makePriceValidForIntegrationListings = (listings) => {
  if (listings && listings.length) {
    return listings.map((st) => {
      const priceMaybe = (st?.attributes?.price?.amount && st?.attributes?.price?.currency) ? new Money(st?.attributes?.price?.amount, st?.attributes?.price?.currency) : null;
      return { ...st, attributes: { ...st.attributes, price: priceMaybe } };
    })
  } else {
    return [];
  }
}



// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));
  console.log(queryParams, '&&& &&& => queryParams');
  
  // sdk.ownListings.query(params)
  return onQueryListings({ ...queryParams })
    .then(response => {
      const { liveListingsLength = 0, draftListingsLength = 0, closedListingsLength = 0 } = response;
      const ListingsResponse = getProperResponseOfListing(response);
      dispatch(queryListingsSuccess({ pagination: response.data.meta, data: makePriceValidForIntegrationListings(ListingsResponse), liveListingsLength, draftListingsLength, closedListingsLength }));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = (listingId, listings) => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));
  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      const newListings = listings.map((st) => st.id.uuid == listingId.uuid ? { ...st, attributes: response.data.data.attributes } : st);
      dispatch(closeListingSuccess(newListings));

      // <<<<<<<<<<<<<<<< upadate data in algolia >>>>>>>>>>>>>>>>>>>>>>>>>
      try {
        const listing = response.data.data;
        const currentUser = getState().user.currentUser;
        const updateParams = { state: LISTING_STATE_CLOSED };
        algoliaCreateUpdateDeleteData(UPDATE_ALGOLIA_TYPE, REACT_APP_ALGOLIA_LISTING_INDEX, listing, currentUser, updateParams);
      } catch (e) {
        console.log(e, '&&& &&& => e');
      }

      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const deleteListing = (listingId, queryParams) => (dispatch, getState, sdk) => {
  dispatch(onDeleteListingRequest(listingId));

  return sdk.ownListings
    .update({ id: listingId, publicData: { listingEnabled: PUB_listingEnabled_DELETED } }, { expand: true })
    .then(response => {
      return sdk.ownListings
        .close({ id: listingId }, { expand: true });
    })
    .then((response) => {

      setTimeout(() => {
        dispatch(onDeleteListingSucess(true));
        dispatch(queryOwnListings(queryParams));
      }, 1000);

      // <<<<<<<<<<<<<<<< upadate data in algolia >>>>>>>>>>>>>>>>>>>>>>>>>
      try {
        const listing = response.data.data;
        const currentUser = getState().user.currentUser;
        const updateParams = { state: LISTING_STATE_CLOSED, listingEnabled: PUB_listingEnabled_DELETED };
        // const updateParams = {state: "published"};
        algoliaCreateUpdateDeleteData(DELETE_ALGOLIA_TYPE, REACT_APP_ALGOLIA_LISTING_INDEX, listing, currentUser, updateParams);
      } catch (e) {
        console.log(e, '&&& &&& => e');
      }

      return response;
    })
    .catch(e => {
      dispatch(onDeleteListingError(storableError(e)));
    });
};

export const duplicateListing = (listingId, listingData, queryParams) => (dispatch, getState, sdk) => {
  dispatch(onDuplicateListingRequest(listingId));
  const { deleted, state, createdAt, metadata, geolocation, ...rest } = listingData;
  return sdk.ownListings.create({ ...rest }, { expand: true })
    .then((response) => {

      setTimeout(() => {
        dispatch(onDuplicateListingSucess(true));
        dispatch(queryOwnListings(queryParams));
      }, 1000);

      // <<<<<<<<<<<<<<<< add new data in algolia >>>>>>>>>>>>>>>>>>>>>>>>>
      try {
        const listing = response.data.data;
        const currentUser = getState().user.currentUser;
        algoliaCreateUpdateDeleteData(UPDATE_ALGOLIA_TYPE, REACT_APP_ALGOLIA_LISTING_INDEX, listing, currentUser);
      } catch (e) {
        console.log(e, '&&& &&& => e');
      }

      return response;
    })
    .catch(e => {
      dispatch(onDuplicateListingError(storableError(e)));
    });
};

export const openListing = (listingId, listings) => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      const newListings = listings.map((st) => st.id.uuid == listingId.uuid ? { ...st, attributes: response.data.data.attributes } : st)
      dispatch(openListingSuccess(newListings));

      // <<<<<<<<<<<<<<<< upadate data in algolia >>>>>>>>>>>>>>>>>>>>>>>>>
      try {
        const listing = response.data.data;
        const currentUser = getState().user.currentUser;
        const updateParams = { state: LISTING_STATE_PUBLISHED };
        algoliaCreateUpdateDeleteData(UPDATE_ALGOLIA_TYPE, REACT_APP_ALGOLIA_LISTING_INDEX, listing, currentUser, updateParams);
      } catch (e) {
        console.log(e, '&&& &&& => e');
      }

      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const napStoreListings = (queryParams, type) => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const authorId = currentUser.id.uuid;
  dispatch(napStoreListingRequest());
  return onNapStoreListingsApi({ type, authorId })
    .then(response => {
      dispatch(updateProfile({ publicData: { storeNapListingType: type } }))
      setTimeout(() => {
        dispatch(napStoreListingSucess(true));
        dispatch(queryOwnListings(queryParams));
      }, 1000);
      return response;
    })
    .catch(e => {
      dispatch(napStoreListingError(storableError(e)));
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const ownlistingParams = getImageParams(search, config);
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      dispatch(queryOwnListings({ ...ownlistingParams, authorId: currentUser.id.uuid }))
      return response;
    })
    .catch(e => {
      throw e;
    });
};
